import { OwnModal } from 'components/modal/OwnModal';
import { Button, ButtonColor } from 'components/uikit/buttons/Button';
import { Field } from 'components/uikit/Field';
import { FileInput } from 'components/uikit/inputs/FileInput';
import { Input } from 'components/uikit/inputs/Input';
import { Tags } from 'components/uikit/inputs/tags/Tags';
import { foodTags } from 'components/uikit/inputs/tags/values';
import { TextArea } from 'components/uikit/inputs/TextArea';
import { Loading } from 'components/uikit/suspense/Loading';
import { useAdvancedForm } from 'helpers/form/advanced-form';
import { requiredRule } from 'helpers/form/react-hook-form-helper';
import React, { FC, useCallback } from 'react';
import { Controller, DefaultValues, FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IStoryDto } from 'services/api/api-client';

const styles = require('pages/authorized/dishes/stories/StoriesPopup.module.scss');

export interface FormStoryDto extends Omit<IStoryDto, 'photoId'> {
  photo: File | null | string;
}

type Props = {
  title: string;
  opened: boolean;
  defaultValues?: DefaultValues<FormStoryDto>;
  onClose: () => void;
  onSubmit: (data: FormStoryDto) => Promise<void>;
};

export const CreateOrEditStoryPopup: FC<Props> = (props) => {
  const { t } = useTranslation();

  const form = useAdvancedForm<FormStoryDto>(useCallback(props.onSubmit, []), {
    defaultValues: props.defaultValues,
  });

  return (
    <OwnModal
      title={props.title}
      opened={props.opened}
      onClose={props.onClose}
      className={styles.storyPopup}
    >
      <Loading loading={form.formState.isSubmitting}>
        <form onSubmit={form.handleSubmitDefault}>
          <Field required title={t('Dishes.Story.Fields.Name')}>
            <Input
              {...form.register('name', { ...requiredRule() })}
              errorText={form.formState.errors.name?.message}
              placeholder={t('Dishes.Story.Fields.Name')}
            />
          </Field>
          <Field
            required
            title={t('Dishes.Story.Fields.Tag')}
            helper={t('Dishes.Story.Fields.Tag.Helper')}
          >
            <Controller
              name={'tag'}
              control={form.control}
              rules={{ ...requiredRule() }}
              render={({ field }) => (
                <Tags
                  {...field}
                  tags={foodTags}
                  multiple={false}
                  value={field.value}
                  onChange={field.onChange}
                  errorText={form.formState.errors.tag?.message}
                />
              )}
            />
          </Field>
          <Field required title={t('Dishes.Story.Fields.Description')}>
            <TextArea
              {...form.register('description', { ...requiredRule() })}
              className={styles.storyPopupTextArea}
              placeholder={t('Dishes.Story.Fields.Description')}
              errorText={form.formState.errors.description?.message}
            />
          </Field>
          <Field title={t('Dishes.Story.Fields.Link')}>
            <Input
              {...form.register('link')}
              errorText={form.formState.errors.link?.message}
              placeholder={t('Dishes.Story.Fields.Link')}
            />
          </Field>
          <Field title={t('Dishes.Story.Fields.LinkName')}>
            <Input
              {...form.register('linkName')}
              errorText={form.formState.errors.linkName?.message}
              placeholder={t('Dishes.Story.Fields.LinkName')}
            />
          </Field>
          <Field required title={t('Dishes.Story.Fields.Photo')}>
            <Controller
              name={'photo'}
              control={form.control}
              rules={{ ...requiredRule() }}
              render={({ field }) => (
                <FileInput
                  {...field}
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                    form.clearErrors('photo');
                  }}
                  description={t('Dishes.Story.Fields.Photo.Description')}
                  errorText={
                    ((form.formState.errors.photo as unknown) as FieldError)
                      ?.message
                  }
                />
              )}
            />
          </Field>
          <div className={styles.storyPopupButtons}>
            <Button
              onClick={props.onClose}
              title={t('Buttons.Cancel')}
              color={ButtonColor.Secondary}
            />
            <Button type={'submit'} title={t('Buttons.Create')} />
          </div>
        </form>
      </Loading>
    </OwnModal>
  );
};
