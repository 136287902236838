import clsx from 'clsx';
import { ImagePreview } from 'components/preview/ImagePreview';
import { FileInputUploader } from 'components/uikit/inputs/FileInput';
import { convertToErrorString } from 'helpers/form/useErrorHandler';
import React, { useRef, useState } from 'react';
import { QueryFactory } from 'services/api';

const styles = require('pages/authorized/aboutUs/AboutUs.module.scss');

export const AboutUsPictureElement: React.FC<{
  defaultValue: string;
  onSave: (newValue: string) => void;
  buttonText?: string;
}> = (props) => {
  const [isInEditing, setIsInEditing] = useState(false);
  const [fieldValue, setFieldValue] = useState(props.defaultValue);
  const [oldFieldValue, setOldFieldValue] = useState(props.defaultValue);
  const fileInput = useRef<HTMLInputElement>(null);

  const handleValueUpdate = async () => {
    try {
      props.onSave(fieldValue);
      setFieldValue(fieldValue);
      setOldFieldValue(fieldValue);
      setIsInEditing(false);
    } catch (e) {
      alert(convertToErrorString(e));
    }
  };

  return (
    <div className={styles.photoFieldWrapper}>
      <ImagePreview
        className={styles.photoField}
        imageClassName={styles.photoFieldImage}
        photoId={props.defaultValue}
        width={512}
      />
      <div
        className={clsx(styles.photoFieldOverlay, styles.photoField)}
        onClick={() => {
          fileInput.current?.click();
        }}
      >
        <div hidden>
          <FileInputUploader
            inputRef={fileInput}
            description={''}
            onChange={(guid) => {
              if (guid) {
                props.onSave(guid);
              }
            }}
          />
        </div>
        <div className={styles.photoFieldOverlayContentIcon} />
        <div className={styles.photoFieldOverlayContent}>
          {props.buttonText}
        </div>
      </div>
    </div>
  );
};
