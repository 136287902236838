import { OwnModal } from 'components/modal/OwnModal';
import { Button, ButtonColor } from 'components/uikit/buttons/Button';
import { Field } from 'components/uikit/Field';
import { FileInput } from 'components/uikit/inputs/FileInput';
import { Input } from 'components/uikit/inputs/Input';
import { Tags } from 'components/uikit/inputs/tags/Tags';
import { foodTags } from 'components/uikit/inputs/tags/values';
import { Loading } from 'components/uikit/suspense/Loading';
import { useAdvancedForm } from 'helpers/form/advanced-form';
import { requiredRule } from 'helpers/form/react-hook-form-helper';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { IDishDto } from 'services/api/api-client';
import {
  Controller,
  DefaultValues,
  FieldError,
  NestedValue,
} from 'react-hook-form';

const styles = require('./CreateOrEditDishPopup.module.scss');

type Props = {
  title: string;
  opened: boolean;
  defaultValues?: DefaultValues<FormDishDto>;
  onClose: () => void;
  onSubmit: (data: FormDishDto) => Promise<void>;
};

export interface FormDishDto extends Omit<IDishDto, 'photoId' | 'sortId'> {
  tags: string[];
  photo?: File | null;
}

export const CreateOrEditDishPopup: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  const form = useAdvancedForm<FormDishDto>(
    useCallback(async (data) => {
      await props.onSubmit(data);
      props.onClose();
    }, []),
    {
      shouldResetOnSuccess: true,
      defaultValues: props.defaultValues,
    },
  );

  const imageMinWidthInPx = 600;

  const checkImageSize = (
    imageRef: React.RefObject<HTMLImageElement | null>,
  ) => {
    const imageWidth = imageRef?.current?.naturalWidth;
    if (imageWidth && imageWidth < imageMinWidthInPx) {
      form.setError('photo', {
        type: 'validation',
        message: t('Dishes.Create.Fields.Photo.ErrorWidth'),
      });
    }
  };

  return (
    <OwnModal
      title={props.title}
      opened={props.opened}
      onClose={props.onClose}
      className={styles.createDish}
    >
      <Loading loading={form.formState.isSubmitting}>
        <form onSubmit={form.handleSubmitDefault}>
          <Field required title={t('Dishes.Create.Fields.Name')}>
            <Input
              {...form.register('name', { ...requiredRule() })}
              errorText={form.formState.errors.name?.message}
              placeholder={t('Dishes.Create.Fields.Name')}
            />
          </Field>
          <Field required title={t('Dishes.Create.Fields.Ingredients')}>
            <Input
              {...form.register('ingredients', { ...requiredRule() })}
              errorText={form.formState.errors.ingredients?.message}
              placeholder={t('Dishes.Create.Fields.Ingredients')}
            />
          </Field>
          <Field required title={t('Dishes.Create.Fields.Price')}>
            <div className={styles.createDishPrice}>
              <Input
                {...form.register('price', { ...requiredRule() })}
                errorText={form.formState.errors.price?.message}
                placeholder={t('Dishes.Create.Fields.Price')}
                className={styles.createDishPriceInput}
                type={'number'}
                step={'.01'}
              />
              <div className={styles.createDishPriceCurrency}>€</div>
            </div>
          </Field>
          <Field
            title={t('Dishes.Create.Fields.Tags')}
            helper={t('Dishes.Create.Fields.Tags.Helper')}
          >
            <Controller
              name={'tags'}
              control={form.control}
              render={({ field }) => (
                <Tags
                  {...field}
                  multiple={true}
                  tags={foodTags}
                  value={field.value}
                  onChange={field.onChange}
                  // errorText={form.formState.errors.tags?.message}
                />
              )}
            />
          </Field>
          <Field
            title={t('Dishes.Create.Fields.Photo')}
            helper={t('Dishes.Create.Fields.Photo.Helper')}
          >
            <Controller
              name={'photo'}
              control={form.control}
              render={({ field }) => (
                <FileInput
                  {...field}
                  value={field.value}
                  onChange={(value) => {
                    field.onChange(value);
                    form.clearErrors('photo');
                  }}
                  onPreviewLoaded={checkImageSize}
                  description={t('Dishes.Create.Fields.Photo.Description')}
                  errorText={
                    ((form.formState.errors.photo as unknown) as FieldError)
                      ?.message
                  }
                />
              )}
            />
          </Field>
          <div className={styles.createDishButtons}>
            <Button
              onClick={props.onClose}
              title={t('Buttons.Cancel')}
              color={ButtonColor.Secondary}
            />
            <Button type={'submit'} title={t('Buttons.Create')} />
          </div>
        </form>
      </Loading>
    </OwnModal>
  );
};
