import { Routes } from 'application/constants/routes';
import { Header } from 'components/header/Header';
import { QuerySuspenseErrorWrapper } from 'helpers/retry-helper';
import { RootPage } from 'pages/authorized/RootPage';
import { LoginPage } from 'pages/unauthorized/login/LoginPage';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { useIsAuthorized } from 'helpers/interceptors/auth/auth-interceptor';

const styles = require('./router.module.scss');

export const AppRouter = () => {
  const isAuthorized = useIsAuthorized();

  return (
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <QuerySuspenseErrorWrapper>
          <Header canLogout={isAuthorized} />
          <div className={styles.rootContent}>
            {isAuthorized ? (
              <RootPage />
            ) : (
              <>
                <Switch>
                  <Route
                    path={Routes.Unauthorized.Login}
                    component={LoginPage}
                  />
                  <Route component={LoginPage} />
                </Switch>
              </>
            )}
          </div>
        </QuerySuspenseErrorWrapper>
      </QueryParamProvider>
    </BrowserRouter>
  );
};
