import React from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFactory } from 'services/api';
import {
  BranchInfoEditableDto,
  IPatchAboutUsDto,
  IPatchSettingsDto,
  PatchAboutUsDto,
  PatchSettingsDto,
} from 'services/api/api-client';
import { AboutUsHoursInputElement } from 'pages/authorized/aboutUs/components/AboutUsHoursInputElement';
import { AboutUsPictureElement } from 'pages/authorized/aboutUs/components/AboutUsPictureElement';
import { AboutUsTextAreaElement } from 'pages/authorized/aboutUs/components/AboutUsTextAreaElement';
import { AboutUsInputElement } from 'pages/authorized/aboutUs/components/AboutUsInputElement';
import {
  parseTimeToSeconds,
  secondsToString,
} from '../../../helpers/date-helpers';

const styles = require('./AboutUs.module.scss');

export const AboutUs: React.FC = () => {
  const { t } = useTranslation();
  const query = QueryFactory.SettingsQuery.useGetForAdminQuery();
  const data = query.data;
  if (!data || !data.aboutUs) return null;

  async function onSaveSettings(data: IPatchSettingsDto) {
    await QueryFactory.SettingsQuery.Client().patch(new PatchSettingsDto(data));
    await query.refetch();
  }

  async function onSave(data: IPatchAboutUsDto) {
    await onSaveSettings({
      aboutUs: new PatchAboutUsDto(data),
    });
  }

  return (
    <div className={styles.aboutUs}>
      <div className={styles.aboutUsHeader}>
        <div className={styles.aboutUsHeaderTitle}>{t('AboutUs.Title')}</div>
      </div>
      <div className={styles.aboutUsContents}>
        <div className={styles.aboutUsContentsColumn}>
          <div className={styles.infoBlock}>
            <div className={styles.infoBlockTitle}>
              {t('AboutUs.Location.Title')}
            </div>
            <div className={styles.infoBlockContent}>
              <AboutUsInputElement
                name={t('AboutUs.Location.Address')}
                defaultValue={data.aboutUs.locationAddress}
                onSave={(newValue) =>
                  onSave({
                    locationAddress: newValue,
                  })
                }
              />
              <AboutUsInputElement
                name={t('AboutUs.Location.GeoLink')}
                defaultValue={data.aboutUs.locationLink}
                onSave={(newValue) =>
                  onSave({
                    locationLink: newValue,
                  })
                }
                hint={t('AboutUs.Location.GeoLink.Hint')}
              />
            </div>
          </div>

          <div className={styles.infoBlock}>
            <div className={styles.infoBlockTitle}>
              {t('AboutUs.WorkingHours.Title')}
            </div>
            <div className={styles.infoBlockContent}>
              <AboutUsHoursInputElement
                name={data.aboutUs.workingHours[0]?.name ?? ''}
                defaultValue={data.aboutUs.workingHours?.[0] ?? {}}
                onSave={async (newValue) => {
                  const newWorkingHours = data.aboutUs.workingHours;
                  newWorkingHours[0] = new BranchInfoEditableDto(newValue);
                  await onSave({
                    workingHours: newWorkingHours,
                  });
                }}
              />
              <AboutUsInputElement
                name={t('AboutUs.WorkingHours.Message', {
                  name: data.aboutUs.workingHours[0]?.name,
                })}
                defaultValue={data.aboutUs.workingHours[0]?.message ?? ''}
                onSave={async (newValue) => {
                  const newWorkingHours = data.aboutUs.workingHours;
                  newWorkingHours[0].message = newValue;
                  await onSave({
                    workingHours: newWorkingHours,
                  });
                }}
              />
              <AboutUsHoursInputElement
                name={data.aboutUs.workingHours[1]?.name ?? ''}
                defaultValue={data.aboutUs.workingHours[1] ?? {}}
                onSave={(newValue) => {
                  const newWorkingHours = data.aboutUs.workingHours;
                  newWorkingHours[1] = new BranchInfoEditableDto(newValue);
                  onSave({
                    workingHours: newWorkingHours,
                  });
                }}
              />
              <AboutUsInputElement
                name={t('AboutUs.WorkingHours.Message', {
                  name: data.aboutUs.workingHours[1]?.name,
                })}
                defaultValue={data.aboutUs.workingHours[1]?.message ?? ''}
                onSave={async (newValue) => {
                  const newWorkingHours = data.aboutUs.workingHours;
                  newWorkingHours[1].message = newValue;
                  await onSave({
                    workingHours: newWorkingHours,
                  });
                }}
              />
            </div>
          </div>
          <div className={styles.infoBlock}>
            <div className={styles.infoBlockTitle}>
              {t('AboutUs.Contacts.Title')}
            </div>

            <div className={styles.infoBlockContent}>
              <AboutUsInputElement
                name={t('AboutUs.Contacts.Phone')}
                defaultValue={data.aboutUs.phone}
                onSave={(newValue) =>
                  onSave({
                    phone: newValue,
                  })
                }
              />
              <AboutUsInputElement
                name={t('AboutUs.Contacts.Mail')}
                defaultValue={data.aboutUs.email}
                onSave={(newValue) =>
                  onSave({
                    email: newValue,
                  })
                }
              />
              <AboutUsInputElement
                name={t('AboutUs.Contacts.Website')}
                defaultValue={data.aboutUs.website}
                onSave={(newValue) =>
                  onSave({
                    website: newValue,
                  })
                }
              />
            </div>
          </div>
        </div>

        <div className={styles.aboutUsContentsColumn}>
          <div className={styles.infoBlock}>
            <div className={styles.infoBlockTitle}>
              {t('AboutUs.SocialMedia.Title')}
            </div>

            <div className={styles.infoBlockContent}>
              <AboutUsInputElement
                name={t('AboutUs.SocialMedia.Facebook')}
                defaultValue={data.aboutUs.facebook}
                onSave={(newValue) =>
                  onSave({
                    facebook: newValue,
                  })
                }
              />
              <AboutUsInputElement
                name={t('AboutUs.SocialMedia.Instagram')}
                defaultValue={data.aboutUs.instagram}
                onSave={(newValue) =>
                  onSave({
                    instagram: newValue,
                  })
                }
              />
              <AboutUsInputElement
                name={t('AboutUs.SocialMedia.Youtube')}
                defaultValue={data.aboutUs.youtube}
                onSave={(newValue) =>
                  onSave({
                    youtube: newValue,
                  })
                }
              />
              <AboutUsInputElement
                name={t('AboutUs.SocialMedia.Twitter')}
                defaultValue={data.aboutUs.twitter}
                onSave={(newValue) =>
                  onSave({
                    twitter: newValue,
                  })
                }
              />
            </div>
          </div>
          <div className={styles.photoInfoBlock}>
            <div className={styles.infoBlockTitle}>
              {t('AboutUs.AboutUs.Title')}
            </div>
            <div className={styles.photoInfoBlockContent}>
              <div className={styles.infoBlockTable}>
                <AboutUsInputElement
                  name={t('AboutUs.AboutUs.TitleField')}
                  defaultValue={data.aboutUs.aboutUsTitle}
                  onSave={(newValue) =>
                    onSave({
                      aboutUsTitle: newValue,
                    })
                  }
                />
                <AboutUsTextAreaElement
                  name={t('AboutUs.AboutUs.Description')}
                  defaultValue={data.aboutUs.aboutUsDescription}
                  onSave={(newValue) =>
                    onSave({
                      aboutUsDescription: newValue,
                    })
                  }
                  minRows={3}
                />
              </div>
              <AboutUsPictureElement
                defaultValue={data.aboutUs.aboutUsPhotoId}
                onSave={(newValue) => {
                  onSave({
                    aboutUsPhotoId: newValue,
                  });
                }}
                buttonText={t('AboutUs.AboutUs.UploadNewPhoto')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
