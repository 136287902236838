import { QueryFactory } from '../services/api';

export const usePhotoQuery = (id?: string, width?: number, height?: number) =>
  QueryFactory.FileQuery.useGetPhotoQuery(id!, width, height, {
    enabled: !!id,
    retry: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    suspense: false,
    onError: (e: any) => {
      if (e.status === 404) {
        return;
      }
      throw e;
    },
  });
