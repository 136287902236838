import { AppTable } from 'components/uikit/AppTable';
import { TablePagination } from 'components/uikit/TablePagination';
import { ConfirmToastTemplate } from 'components/uikit/toasts/ConfirmToastTemplate';
import equal from 'fast-deep-equal/react';
import {
  pagingSortingQueryParams,
  pagingSortingToBackendRequest,
} from 'helpers/pagination-helper';
import { emptyArray } from 'helpers/useUpdateSortByInUrl';
import { DishesCell } from 'pages/authorized/orders/cells/DishesCell';
import { PickupTimeCell } from 'pages/authorized/orders/cells/PickupTimeCell';
import { WeeklyStatistics } from 'pages/authorized/orders/weekly-statistics/WeeklyStatistics';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CellProps, Column, Row, useTable } from 'react-table';
import { toast } from 'react-toastify';
import { QueryFactory } from 'services/api';
import {
  IOrderDto,
  OrderDto,
  OrderState,
  UpdateDailyDishDto,
} from 'services/api/api-client';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { CustomerCell } from './cells/CustomerCell';

const styles = require('./Orders.module.scss');

export const Orders: React.FC = () => {
  const { t } = useTranslation();
  const [query, setQuery] = useQueryParams({
    tab: withDefault(StringParam, OrderState.Pending),
    ...pagingSortingQueryParams(10, false),
  });

  const [busyRows, setBusyRows] = useState<number[] | null>(null);
  const ordersState = query.tab as OrderState;
  const ordersQuery = QueryFactory.OrderQuery.useGetAllQuery(
    {
      state: ordersState,
      ...pagingSortingToBackendRequest(query),
    },
    {
      refetchOnWindowFocus: query.tab === OrderState.Pending,
      refetchIntervalInBackground: query.tab === OrderState.Pending,
      refetchInterval: query.tab === OrderState.Pending ? 60 * 1000 : false,
      keepPreviousData: true,
      isDataEqual: equal,
    },
  );
  const weeklyStatisticsQuery = QueryFactory.OrderQuery.useGetStatisticsQuery();

  const showRestoreToast = (order: OrderDto, newOrderState: OrderState) => {
    toast(
      <ConfirmToastTemplate
        onConfirm={() => handleOnProcessClick(order.id, newOrderState)}
        message={t('Orders.RestoreToast.Message')}
        confirmText={t('Orders.RestoreToast.Restore')}
        cancelText={t('Orders.RestoreToast.Cancel')}
      />,
      {
        toastId: order.number,
        hideProgressBar: true,
        className: styles.toastBase,
        closeOnClick: false,
        closeButton: false,
        style: { zIndex: 8, width: 'auto', minHeight: '45px' },
        position: 'bottom-right',
      },
    );
  };

  useEffect(() => {
    setQuery({
      tab: ordersState,
    });
  }, [ordersState]);

  const handleOnProcessClick = useCallback(
    async (orderId: number, state: OrderState) => {
      await setBusyRows((prevState) =>
        prevState !== null ? [...prevState, orderId] : [orderId],
      );
      await QueryFactory.OrderQuery.Client().process(orderId, state);
      await ordersQuery.refetch();
      await setBusyRows(
        (prevState) => prevState?.filter((x) => x !== orderId) ?? null,
      );
      weeklyStatisticsQuery.refetch();
    },
    [],
  );

  const table = useTable<IOrderDto>({
    data: ordersQuery.data?.data ?? emptyArray,
    columns: useMemo(() => {
      const columns: Column<IOrderDto>[] = [
        {
          width: '10%',
          accessor: 'number',
          Header: () => t('Orders.List.Number'),
          Cell: ({ row }) => (
            <div className={styles.ordersTableNumber}>
              {row.original.number}
            </div>
          ),
        },
        {
          width: '12%',
          accessor: 'pickupTime',
          Header: () => t('Orders.List.PickupTime'),
          Cell: ({ row }) => <PickupTimeCell date={row.original.pickupTime} />,
        },
        {
          width: '23%',
          accessor: 'dishes',
          Header: () => t('Orders.List.Dishes'),
          Cell: ({ row }) => <DishesCell dishes={row.original.dishes} />,
        },
        {
          width: '10%',
          accessor: 'totalPrice',
          Header: () => t('Orders.List.TotalPrice'),
          Cell: ({ row }) => (
            <div className={styles.ordersTableNowrap}>
              {row.original.totalPrice.toFixed(2)} €
            </div>
          ),
        },
        {
          width: '15%',
          accessor: 'name',
          Header: () => t('Orders.List.Name'),
          Cell: ({ row }) => (
            <CustomerCell
              name={row.original.name}
              phoneNumber={row.original.phoneNumber}
            />
          ),
        },
        {
          width: 'auto',
          accessor: 'comment',
          Header: () => t('Orders.List.Comment'),
        },
      ];
      if (ordersState !== OrderState.Declined) {
        columns.push({
          width: 48,
          id: '[decline-button]',
          Cell: ({ row: { original } }: CellProps<OrderDto>) => (
            <div
              data-type={'decline'}
              className={styles.ordersTableButton}
              onClick={() => {
                handleOnProcessClick(original.id, OrderState.Declined);
                showRestoreToast(original, ordersState);
              }}
            />
          ),
        });
      }
      if (ordersState !== OrderState.Approved) {
        columns.push({
          width: 48,
          id: '[approve-button]',
          Cell: ({
            row: {
              original: { id },
            },
          }: CellProps<OrderDto>) => (
            <div
              data-type={'approve'}
              className={styles.ordersTableButton}
              onClick={() => handleOnProcessClick(id, OrderState.Approved)}
            />
          ),
        });
      }
      return columns;
    }, [handleOnProcessClick, ordersState]),
  });

  const handleOnSoldOutChange = async (
    date: Date,
    dishId: number,
    isOutOfStock: boolean,
  ) => {
    await QueryFactory.DailyMenuQuery.Client().updateDailyDish(
      date,
      new UpdateDailyDishDto({
        id: dishId,
        isOutOfStock: isOutOfStock,
        sortIndex: null,
      }),
    );
    weeklyStatisticsQuery.refetch();
  };

  const getCustomRowProps = useCallback(
    (row: Row<IOrderDto>) => {
      return {
        ['data-disabled']: busyRows?.some((x) => x === row.original.id),
      };
    },
    [busyRows],
  );

  const memoizedTable = useMemo(
    () => <AppTable table={table} customRowProps={getCustomRowProps} />,
    [table, ordersQuery.data?.data, getCustomRowProps],
  );

  return (
    <div className={styles.root}>
      <div className={styles.orders}>
        <div className={styles.ordersHeader}>
          <div className={styles.ordersHeaderTitleWithDate}>
            <div className={styles.ordersHeaderTitle}>{t('Orders.Title')}</div>
            {/*<AcceptUntil />*/}
          </div>
          <div className={styles.ordersHeaderStates}>
            <div
              className={styles.ordersHeaderStatesState}
              data-active={ordersState === OrderState.Pending}
              onClick={() => setQuery({ tab: OrderState.Pending })}
            >
              {t('Orders.Tabs.New')}
            </div>
            <div
              className={styles.ordersHeaderStatesState}
              data-active={ordersState === OrderState.Approved}
              onClick={() => setQuery({ tab: OrderState.Approved })}
            >
              {t('Orders.Tabs.Approved')}
            </div>
            <div
              className={styles.ordersHeaderStatesState}
              data-active={ordersState === OrderState.Declined}
              onClick={() => setQuery({ tab: OrderState.Declined })}
            >
              {t('Orders.Tabs.Declined')}
            </div>
          </div>
          <div />
        </div>
        <div className={styles.ordersTable}>
          {memoizedTable}
          <TablePagination
            page={query.page}
            perPage={query.perPage}
            changePagination={setQuery}
            totalCount={ordersQuery.data?.totalCount ?? 0}
          />
        </div>
      </div>
      <WeeklyStatistics onSoldOutChange={handleOnSoldOutChange} />
    </div>
  );
};
