import { Modal } from '@material-ui/core';
import clsx from 'clsx';
import React, { FC } from 'react';

const styles = require('components/modal/OwnModal.module.scss');

type Props = {
  title: string;
  opened: boolean;
  className?: string;
  onClose: () => void;
};

export const OwnModal: FC<Props> = (props) => {
  return (
    <Modal
      open={props.opened}
      onClose={props.onClose}
      className={styles.confirmationBoxModal}
    >
      <div className={clsx(styles.confirmationBox, props.className)}>
        <div className={styles.confirmationBoxHeader}>
          <div>{props.title}</div>
          <div
            className={styles.confirmationBoxClose}
            onClick={props.onClose}
          />
        </div>
        <div className={styles.confirmationBoxContent}>{props.children}</div>
      </div>
    </Modal>
  );
};
