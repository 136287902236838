import React, { FC } from 'react';

const styles = require('./CloseButton.module.scss');

type Props = {
  onClick?: () => void;
};

const CloseIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4194 5.58055L5.58058 14.4194M5.58058 5.58055L14.4194 14.4194"
        stroke="#DE2034"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const CloseButton: FC<Props> = (props) => {
  return (
    <div onClick={props.onClick} className={styles.closeButton}>
      <CloseIcon />
    </div>
  );
};
