import { AxiosError } from 'axios';
import { QueryClient, useMutation } from 'react-query';
import { QueryFactory } from 'services/api';
import { DailyDishDto, UpdateDailyDishDto } from 'services/api/api-client';

export const addDishToDailyMenuMutation = (queryClient: QueryClient) =>
  useMutation<DailyDishDto, AxiosError, { date: Date; dishId: number }>(
    ({ date, dishId }) => {
      return QueryFactory.DailyMenuQuery.Client().addDish(date, dishId);
    },
    {
      onSuccess: (response, { date }) => {
        queryClient.invalidateQueries(
          QueryFactory.DailyMenuQuery.getWeeklyMenuQueryKey(undefined!),
        );
        queryClient.invalidateQueries(
          QueryFactory.DailyMenuQuery.getWeeklyMenuV2QueryKey(),
        );
      },
    },
  );

export const removeDishFromDailyMenuMutation = (queryClient: QueryClient) =>
  useMutation<void, AxiosError, { date: Date; dishId: number }>(
    ({ date, dishId }) => {
      return QueryFactory.DailyMenuQuery.Client().removeDish(date, dishId);
    },
    {
      onSuccess: (_, { date, dishId }) => {
        queryClient.invalidateQueries(
          QueryFactory.DailyMenuQuery.getWeeklyMenuQueryKey(undefined!),
        );
        queryClient.invalidateQueries(
          QueryFactory.DailyMenuQuery.getWeeklyMenuV2QueryKey(),
        );
      },
    },
  );

export const changeDishOrderSortingMutation = (queryClient: QueryClient) =>
  useMutation<
    void,
    AxiosError,
    { date: Date; dishId: number; newSortIndex: number }
  >(
    ({ date, dishId, newSortIndex }) => {
      return QueryFactory.DailyMenuQuery.Client().updateDailyDish(
        date,
        new UpdateDailyDishDto({
          id: dishId,
          sortIndex: newSortIndex,
          isOutOfStock: null,
        }),
      );
    },
    {
      onSuccess: (_, { date, dishId, newSortIndex }) => {
        queryClient.invalidateQueries(
          QueryFactory.DailyMenuQuery.getWeeklyMenuQueryKey(undefined!),
        );
        queryClient.invalidateQueries(
          QueryFactory.DailyMenuQuery.getWeeklyMenuV2QueryKey(),
        );
      },
    },
  );
