import { Loading } from 'components/uikit/suspense/Loading';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrinterManagementQuery } from 'services/api/api-client';

const styles = require('./ReceiptsCounter.module.scss');

export const ReceiptsCounter = () => {
  const { t } = useTranslation();
  const { data } = PrinterManagementQuery.useGetPrinterStatusQuery({
    refetchInterval: 15 * 1000,
    refetchOnWindowFocus: true,
    refetchIntervalInBackground: true,
  });

  return (
    <div className={styles.container}>
      <span>{t('ReceiptsCounter.Count')}</span>
      <span className={styles.value}>{data?.receiptsQueueCount ?? '...'}</span>
    </div>
  );
};
