import { Routes } from 'application/constants/routes';
import { Menu } from 'components/menu/Menu';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Dishes } from './dishes/Dishes';
import { Orders } from './orders/Orders';
import { AboutUs } from './aboutUs/AboutUs';
import { Settings } from './settings/Settings';

const styles = require('./RootPage.module.scss');

export const RootPage: React.FC = () => {
  return (
    <>
      <div className={styles.container}>
        <Menu>
          <Switch>
            <Route path={Routes.Authorized.Orders} component={Orders} />
            <Route path={Routes.Authorized.Dishes} component={Dishes} />
            <Route path={Routes.Authorized.AboutUs} component={AboutUs} />
            <Route path={Routes.Authorized.Settings} component={Settings} />
            <Redirect
              from={Routes.Authorized.Root}
              to={Routes.Authorized.Orders}
            />
          </Switch>
        </Menu>
      </div>
    </>
  );
};
