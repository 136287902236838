import clsx from 'clsx';
import { StoriesPopup } from 'pages/authorized/dishes/stories/StoriesPopup';
import React, { FC, useState } from 'react';

const styles = require('pages/authorized/dishes/Dishes.module.scss');

type Props = {
  count: number;
  dishId: number;
};

export const StoriesCell: FC<Props> = (props) => {
  const [opened, setOpened] = useState(false);

  return (
    <>
      <div onClick={() => setOpened(true)} className={styles.stories}>
        <div className={clsx(styles.dishesTableStories, styles.storiesCount)}>
          {props.count}
        </div>
      </div>
      {opened && (
        <StoriesPopup
          opened={opened}
          dishId={props.dishId}
          onClose={() => {
            setOpened(false);
          }}
        />
      )}
    </>
  );
};
