import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const styles = require('components/uikit/inputs/tags/Tags.module.scss');

export type TagType = {
  key: string;
  name?: string;
  icon?: ReactNode;
  bigIcon?: ReactNode;
  color?: 'red' | 'green' | 'orange' | 'blue';
};

type SingleTagProps = {
  multiple: false;
  value?: string;
  onChange: (value?: string) => void;
};

type MultipleTagsProps = {
  multiple: true;
  value?: string[];
  onChange: (value: string[]) => void;
};

type TagsProps = {
  tags: TagType[];
  errorText?: string;
  tagClassName?: string;
} & (SingleTagProps | MultipleTagsProps);

export const Tags = React.forwardRef<HTMLSelectElement, TagsProps>(
  function Tags(props, ref) {
    const handleOnChange = (key: string) => {
      if (props.multiple) {
        if (props.value?.includes(key)) {
          props.onChange(props.value.filter((x) => x !== key));
        } else {
          props.onChange([...(props?.value ?? []), key]);
        }
      } else {
        key === props.value ? props.onChange() : props.onChange(key);
      }
    };

    const selectOptions = props.tags.map((x) => x.key);

    return (
      <div className={styles.tags}>
        <select
          ref={ref}
          value={props.value}
          className={styles.tagsSelect}
          multiple={props.multiple ?? true}
          onChange={(e) => handleOnChange(e.target.value)}
        >
          {selectOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        {props.tags.map((tag) => (
          <Tag
            {...tag}
            key={tag.key}
            interactable={true}
            className={props.tagClassName}
            onClick={() => handleOnChange(tag.key)}
            selected={
              props.multiple
                ? props.value?.includes(tag.key) ?? false
                : props.value === tag.key
            }
          />
        ))}
        {!!props.errorText && (
          <div className={styles.tagsError}>{props.errorText}</div>
        )}
      </div>
    );
  },
);

interface TagProps extends TagType {
  onClick?: () => void;
  className?: string;
  selected: boolean;
  interactable?: boolean;
}

export const Tag: React.FC<TagProps> = (props) => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx(
        styles.tagsTag,
        {
          [styles.selected]: props.selected,
          [styles.interactable]: props.interactable,
        },
        props.className,
      )}
      onClick={props.onClick}
      data-text-color={props.color}
    >
      {props.icon}
      {props.name && (
        <div className={styles.tagsTagText}>{t(props.name as any)}</div>
      )}
    </div>
  );
};
