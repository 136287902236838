import { getEnvironmentVariableValue } from 'helpers/environment-helper';

export const appVersion = () =>
  getEnvironmentVariableValue('$REACT_APP_VERSION');

export const sentryDsn = () =>
  getEnvironmentVariableValue('https://697e7d4f97bc446c8b5a015812607c53@o738004.ingest.sentry.io/5883769');

export const getClientKey = () =>
  getEnvironmentVariableValue('daba4fbc-d426-4200-80c9-feed63479380') ||
  '3901bd55-5ba2-41a3-bd0c-962fb0a5363d';
