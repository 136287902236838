import { ConfirmationModal } from 'components/modal/ConfirmationModal';
import { ReactComponent as DeleteDishIcon } from 'pages/authorized/dishes/icons/DeleteDish.svg';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

const styles = require('pages/authorized/dishes/Dishes.module.scss');

type Props = {
  dishId: number;
  onDelete: (dishId: number) => Promise<void>;
};

export const DeleteCell: FC<Props> = (props) => {
  const { t } = useTranslation();
  const [deleteOpened, setDeleteOpened] = useState(false);
  return (
    <>
      <div
        className={styles.dishesTableRemove}
        onClick={() => setDeleteOpened(true)}
      >
        <DeleteDishIcon />
      </div>
      <ConfirmationModal
        opened={deleteOpened}
        text={t('Dishes.Delete.Text')}
        title={t('Dishes.Delete.Title')}
        onClose={() => setDeleteOpened(false)}
        onConfirm={() => {
          props.onDelete(props.dishId);
          setDeleteOpened(false);
        }}
      />
    </>
  );
};
