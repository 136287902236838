import clsx from 'clsx';
import React from 'react';
import { ToastProps } from 'react-toastify/dist/types';

const styles = require('./ConfirmToastTemplate.module.scss');

type ConfirmToastTemplateProps = {
  closeToast?: () => void;
  toastProps?: ToastProps;
  onConfirm: () => void;
  onCancel?: () => void;
  message?: string;
  confirmText?: string;
  cancelText?: string;
};

export const ConfirmToastTemplate = (props: ConfirmToastTemplateProps) => {
  const handleConfirm = () => {
    props.onConfirm();
    if (props.closeToast) {
      props.closeToast();
    }
  };

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
    if (props.closeToast) {
      props.closeToast();
    }
  };

  return (
    <div className={styles.toastContent}>
      <div className={styles.toastMessage}>{props.message}</div>
      <div className={styles.toastButtons}>
        <div className={styles.toastButtonsConfirm} onClick={handleConfirm}>
          {props.confirmText}
        </div>
        <div className={styles.toastButtonsClose} onClick={handleCancel}>
          {props.cancelText}
        </div>
      </div>
    </div>
  );
};
