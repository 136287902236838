import React, { FC } from 'react';
import { IOrderedDishDto } from 'services/api/api-client';

const styles = require('../Orders.module.scss');

type Props = {
  dishes?: IOrderedDishDto[];
};

export const DishesCell: FC<Props> = ({ dishes }) => {
  if (!dishes || dishes.length === 0) {
    return null;
  }
  return (
    <div className={styles.dishes}>
      {dishes.map((dish) => {
        const name =
          dish.name.length > 5 ? `${dish.name.substring(0, 4)}.` : dish.name;
        return (
          <div className={styles.dishesDish} key={dish.id}>
            <div className={styles.dishesDishCount}>{dish.quantity}</div>
            <div className={styles.dishesDishName}>{name}</div>
          </div>
        );
      })}
    </div>
  );
};
