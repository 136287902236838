import { foodTags } from 'components/uikit/inputs/tags/values';
import React, { FC } from 'react';

const styles = require('pages/authorized/dishes/Dishes.module.scss');

type Props = {
  tags?: string[];
};

export const TagsCell: FC<Props> = (props) => {
  return (
    <div className={styles.dishesTableTags}>
      {props.tags &&
        props.tags.length > 0 &&
        foodTags
          .filter((t) => props.tags?.includes(t.key))
          .map((tag) => (
            <div key={tag.key} className={styles.dishesTableTagsItem}>
              {tag.bigIcon}
            </div>
          ))}
    </div>
  );
};
