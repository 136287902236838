import { OwnModal } from 'components/modal/OwnModal';
import { Button, ButtonColor } from 'components/uikit/buttons/Button';
import { Loading } from 'components/uikit/suspense/Loading';
import { DailyDish } from 'pages/authorized/dishes/daily-menu/DailyDish';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IOrderStatisticsDto } from 'services/api/api-client';
import {
  getDay,
  startOfToday,
  startOfDay,
  isBefore,
  getUnixTime,
  fromUnixTime,
} from 'date-fns';
import { localFormat } from 'helpers/date-helpers';
import { QueryFactory } from 'services/api';
import { WeekSwitcher } from 'pages/authorized/dishes/daily-menu/WeekSwitcher';

const styles = require('./WeeklyStatistics.module.scss');

type Props = {
  onSoldOutChange: (
    date: Date,
    dishId: number,
    outOfStockValue: boolean,
  ) => void;
};

type OwnOrderStatistics = IOrderStatisticsDto & {
  total: number;
};

enum DayType {
  Past = 'past',
  Present = 'present',
  Future = 'future',
}

type SoldOutState = {
  opened: boolean;
  date?: Date;
  dish?: {
    id: number;
    name: string;
    isOutOfStock: boolean;
  };
};

const initialState: SoldOutState = {
  opened: false,
};

export const WeeklyStatistics: FC<Props> = ({ onSoldOutChange }) => {
  const { t } = useTranslation();
  const [startWeekDate, setStartWeekDate] = useState<Date | undefined>();
  const [soldOutState, setSoldOutState] = useState<SoldOutState>(initialState);
  const acceptOrdersUntilQuery = QueryFactory.SettingsQuery.useGetForAdminQuery();
  const acceptOrdersUntil =
    acceptOrdersUntilQuery.data?.orderTimeLimitInSecondsSinceMidnight ?? 0;
  const weeklyStatisticsQuery = QueryFactory.OrderQuery.useGetStatisticsQuery(
    startWeekDate,
    { enabled: !!startWeekDate },
  );
  const statistics = weeklyStatisticsQuery.data;
  const stats = useMemo(
    () =>
      statistics?.map(
        (stat): OwnOrderStatistics => ({
          ...stat,
          total:
            stat.dishes?.reduce((acc, dish) => acc + dish.quantity, 0) ?? 0,
        }),
      ),
    [statistics],
  );
  const total = stats?.reduce((acc, stat) => acc + stat?.total, 0);

  const setSoldOut = (
    date: Date,
    id: number,
    name: string,
    isOutOfStock: boolean,
  ) => {
    setSoldOutState({
      opened: true,
      date,
      dish: {
        id,
        name,
        isOutOfStock,
      },
    });
  };

  return (
    <div className={styles.weeklyStatistics}>
      <WeekSwitcher onStartWeekChanged={setStartWeekDate} allowPast={true} />
      <div className={styles.weeklyStatisticsHeader}>
        <div className={styles.weeklyStatisticsHeaderTitle}>
          {t('WeeklyStatistics.Title')}
        </div>
        <div className={styles.weeklyStatisticsHeaderTotal}>
          {t('WeeklyStatistics.Total', {
            total,
          })}
        </div>
      </div>
      <Loading
        contentClassName={styles.loadingWrapper}
        loading={
          acceptOrdersUntilQuery.isLoading || weeklyStatisticsQuery.isLoading
        }
      >
        {stats?.map((stat) => {
          const date = stat.date;
          const dayType = isBefore(startOfDay(date), startOfToday())
            ? DayType.Past
            : startOfDay(date) == startOfToday() &&
              isBefore(
                fromUnixTime(getUnixTime(date) + acceptOrdersUntil),
                Date.now(),
              )
            ? DayType.Present
            : DayType.Future;
          return (
            <div
              key={date.toDateString()}
              data-type={dayType}
              className={styles.weeklyStatisticsCard}
            >
              <div className={styles.weeklyStatisticsCardHeaderSoldOutTitle}>
                {t('WeeklyStatistics.SoldOut')}
              </div>
              <div className={styles.weeklyStatisticsCardHeader}>
                <div
                  data-type={dayType}
                  className={styles.weeklyStatisticsCardHeaderWeekday}
                >
                  {localFormat(date, 'EEEE')}
                </div>
                <div className={styles.weeklyStatisticsCardHeaderDate}>
                  {localFormat(date, 'PPP')}
                </div>
                {stat.total > 0 && (
                  <div className={styles.weeklyStatisticsCardHeaderTotal}>
                    {stat.total}
                  </div>
                )}
              </div>
              {stat.dishes?.map((dish) => (
                <>
                  <div
                    key={dish.id + '_Out'}
                    className={styles.weeklyStatisticsCardSoldOutButton}
                  >
                    <div
                      data-disabled={dish.isDeleted}
                      data-checked={dish.isOutOfStock}
                      className={styles.weeklyStatisticsCardSoldOutButtonIcon}
                      onClick={() =>
                        setSoldOut(
                          stat.date,
                          dish.id,
                          dish.name,
                          !dish.isOutOfStock,
                        )
                      }
                    />
                  </div>
                  <DailyDish
                    key={dish.id + '_Data'}
                    id={dish.id}
                    name={dish.name}
                    photoId={dish.photoId}
                    disabled={dish.isDeleted}
                    additionalInfo={dish.quantity}
                    className={styles.weeklyStatisticsCardDish}
                    nameClassName={styles.weeklyStatisticsCardDishName}
                  />
                </>
              ))}
            </div>
          );
        })}
      </Loading>
      <OwnModal
        title={t('SoldOut.Title')}
        opened={soldOutState.opened}
        onClose={() => setSoldOutState(initialState)}
      >
        <div className={styles.weeklyStatisticsModalText}>
          {t('SoldOut.Text', {
            name: soldOutState.dish?.name,
          })}
        </div>
        <div className={styles.weeklyStatisticsModalButtons}>
          <Button
            onClick={() => setSoldOutState(initialState)}
            title={t('Buttons.Cancel')}
            color={ButtonColor.Secondary}
          />
          <Button
            title={t('Buttons.Confirm')}
            onClick={() => {
              if (soldOutState.dish && soldOutState.date) {
                onSoldOutChange(
                  soldOutState.date,
                  soldOutState.dish.id,
                  soldOutState.dish.isOutOfStock,
                );
              }
              setSoldOutState(initialState);
            }}
          />
        </div>
      </OwnModal>
    </div>
  );
};
