import { usePhotoQuery } from 'helpers/query-helpers';
import {
  CreateOrEditDishPopup,
  FormDishDto,
} from 'pages/authorized/dishes/create-or-edit/CreateOrEditDishPopup';
import { ReactComponent as EditDishIcon } from 'pages/authorized/dishes/icons/EditDish.svg';
import React, { FC, useState } from 'react';
import { NestedValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { IDishDto } from 'services/api/api-client';

const styles = require('pages/authorized/dishes/Dishes.module.scss');

type Props = {
  data: IDishDto;
  onEdit: (data: FormDishDto) => Promise<void>;
};

export const EditCell: FC<Props> = (props) => {
  const { t } = useTranslation();
  const [editOpened, setEditOpened] = useState(false);
  const photoQuery = usePhotoQuery(props.data.photoId ?? undefined);

  const photo: File | null =
    photoQuery?.data && photoQuery.data.fileName
      ? new File([photoQuery.data.data], photoQuery.data.fileName)
      : null;
  const formValues: FormDishDto = {
    ...props.data,
    photo: photo,
    tags: (props.data.tags ?? []) as NestedValue<string[]>,
  };

  return (
    <>
      <div
        className={styles.dishesTableEdit}
        onClick={() => setEditOpened(true)}
      >
        <EditDishIcon />
      </div>
      <CreateOrEditDishPopup
        title={t('Dishes.Edit.Title')}
        opened={editOpened}
        onSubmit={props.onEdit}
        defaultValues={formValues}
        onClose={() => setEditOpened(false)}
      />
    </>
  );
};
