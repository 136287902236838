import { AppTable } from 'components/uikit/AppTable';
import { FloatingButton } from 'components/uikit/buttons/FloatingButton';
import { Input } from 'components/uikit/inputs/Input';
import { TablePagination } from 'components/uikit/TablePagination';
import { createFileParameter } from 'helpers/file-helper';
import {
  pagingSortingQueryParams,
  pagingSortingToBackendRequest,
} from 'helpers/pagination-helper';
import { emptyArray } from 'helpers/useUpdateSortByInUrl';
import { DeleteCell } from 'pages/authorized/dishes/cells/DeleteCell';
import { EditCell } from 'pages/authorized/dishes/cells/EditCell';
import { StoriesCell } from 'pages/authorized/dishes/cells/StoriesCell';
import { TagsCell } from 'pages/authorized/dishes/cells/TagsCell';
import {
  CreateOrEditDishPopup,
  FormDishDto,
} from 'pages/authorized/dishes/create-or-edit/CreateOrEditDishPopup';
import { DailyMenu } from 'pages/authorized/dishes/daily-menu/DailyMenu';
import { ReactComponent as CreateDishIcon } from 'pages/authorized/dishes/icons/CreateDish.svg';
import { ReactComponent as DragIcon } from 'pages/authorized/dishes/icons/Drag.svg';
import {
  addDishToDailyMenuMutation,
  changeDishOrderSortingMutation,
  removeDishFromDailyMenuMutation,
} from 'pages/authorized/dishes/mutations';
import NoDishPhoto from 'pages/authorized/dishes/NoDishPhoto.png';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { Cell, CellProps, Row, useTable } from 'react-table';
import { QueryFactory } from 'services/api';
import { FileParameter, IDishDto, Week } from 'services/api/api-client';
import { StringParam, useQueryParams } from 'use-query-params';
import equal from 'fast-deep-equal/react';
import { ImagePreview } from '../../../components/preview/ImagePreview';

const styles = require('./Dishes.module.scss');

export const Dishes: React.FC = () => {
  const editButtonId = '[edit-button]';
  const deleteButtonId = '[delete-button]';
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [dragStarted, setDragStarted] = useState(false);
  const [busyRows, setBusyRows] = useState<number[] | null>(null);
  const [createDishPopupOpened, setCreateDishPopupOpened] = useState(false);

  const [query, setQuery] = useQueryParams({
    search: StringParam,
    ...pagingSortingQueryParams(10, true),
  });
  const dishesQuery = QueryFactory.DishQuery.useSearchQuery(
    {
      dishName: query.search,
      ...pagingSortingToBackendRequest(query),
    },
    {
      keepPreviousData: true,
      isDataEqual: equal,
    },
  );

  const addDishToDailyMenu = addDishToDailyMenuMutation(queryClient);
  const removeDishFromDailyMenu = removeDishFromDailyMenuMutation(queryClient);
  const changeOrderSortingFrDailyMenu = changeDishOrderSortingMutation(
    queryClient,
  );

  const handleOnDishEdit = useCallback(async (data: FormDishDto) => {
    await QueryFactory.DishQuery.Client().updateDish(
      data.id,
      data.name,
      data.ingredients,
      data.price,
      data.tags,
      createFileParameter(data.photo),
    );
    await queryClient.invalidateQueries(
      QueryFactory.DishQuery.searchQueryKey(),
    );
    await queryClient.invalidateQueries(
      QueryFactory.DailyMenuQuery.getWeeklyMenuV2QueryKey(),
    );
    await queryClient.invalidateQueries(
      QueryFactory.DailyMenuQuery.getWeeklyMenuQueryKey(undefined!),
    );
  }, []);

  const handleOnDishDelete = useCallback(async (dishId: number) => {
    try {
      await setBusyRows((prevState) =>
        prevState !== null ? [...prevState, dishId] : [dishId],
      );
      await QueryFactory.DishQuery.Client().delete(dishId);
      await dishesQuery.refetch();
    } finally {
      await setBusyRows(
        (prevState) => prevState?.filter((x) => x !== dishId) ?? null,
      );
    }

    await queryClient.invalidateQueries(
      QueryFactory.DailyMenuQuery.getWeeklyMenuV2QueryKey(),
    );
    await queryClient.invalidateQueries(
      QueryFactory.DailyMenuQuery.getWeeklyMenuQueryKey(undefined!),
    );
  }, []);

  const table = useTable<IDishDto>({
    data: dishesQuery.data?.data ?? emptyArray,
    columns: useMemo(() => {
      return [
        {
          id: '[drag-button]',
          width: 24,
          Cell: () => <DragIcon />,
        },
        {
          Header: () => t('Dishes.List.Photo'),
          accessor: 'photoId',
          width: 32,
          Cell: ({ row }) => {
            return (
              <ImagePreview
                photoId={row.original.photoId}
                fallback={NoDishPhoto}
                className={styles.dishesTablePhoto}
                width={60}
              />
            );
          },
        },
        {
          accessor: 'name',
          Header: () => t('Dishes.List.Name'),
          width: '25%',
          Cell: ({ row }) => (
            <div className={styles.dishesTableBold}>{row.original.name}</div>
          ),
        },
        {
          accessor: 'tags',
          Header: () => t('Dishes.List.Tags'),
          width: '10%',
          Cell: ({ row }) => <TagsCell tags={row.original.tags} />,
        },
        {
          accessor: 'ingredients',
          Header: () => t('Dishes.List.Ingredients'),
          width: 'auto',
        },
        {
          accessor: 'price',
          Header: () => t('Dishes.List.Price'),
          width: '10%',
          Cell: ({ row }) => (
            <div className={styles.dishesTableNowrap}>
              {row.original.price.toFixed(2)} €
            </div>
          ),
        },
        {
          id: 'stories',
          Header: () => (
            <div className={styles.stories}>{t('Dishes.List.Stories')}</div>
          ),
          width: '10%',
          Cell: ({ row }: CellProps<IDishDto>) => (
            <StoriesCell
              dishId={row.original.id}
              count={row.original.storiesCount}
            />
          ),
        },
        {
          id: editButtonId,
          width: 24,
          Cell: ({ row }: CellProps<IDishDto>) => (
            <EditCell data={row.original} onEdit={handleOnDishEdit} />
          ),
        },
        {
          id: deleteButtonId,
          width: 24,
          Cell: ({ row }: CellProps<IDishDto>) => (
            <DeleteCell
              dishId={row.original.id}
              onDelete={handleOnDishDelete}
            />
          ),
        },
      ];
    }, [handleOnDishDelete, handleOnDishEdit]),
  });

  const getCustomCellProps = useCallback((cell: Cell<IDishDto>) => {
    const isActionCell =
      cell.column.id === editButtonId ||
      cell.column.id === deleteButtonId ||
      cell.column.id === 'stories';
    return {
      style: { cursor: isActionCell ? 'default' : 'move' },
      draggable: !isActionCell,
      onDragStart: (e: React.DragEvent<HTMLDivElement>) => {
        setDragStarted(true);
        // Hack to get the "name" of a dish.
        const photoElement = (e.target as HTMLDivElement)?.parentElement
          ?.children?.[2]?.children?.[0];
        e.dataTransfer.setData('text/plain', cell.row.original.id.toString());
        if (photoElement) {
          e.dataTransfer.setDragImage(photoElement, -10, -10);
        }
      },
      onDragEnd: () => {
        setDragStarted(false);
      },
    };
  }, []);

  const getCustomRowProps = useCallback(
    (row: Row<IDishDto>) => {
      return {
        ['data-disabled']: busyRows?.some((x) => x === row.original.id),
      };
    },
    [busyRows],
  );

  const handleOnDrop = async (
    e: React.DragEvent<HTMLDivElement>,
    date: Date,
  ) => {
    const dishId = Number(e.dataTransfer.getData('text/plain'));
    if (!isNaN(dishId)) {
      addDishToDailyMenu.mutate({ date, dishId });
    }
  };

  const handleOnDishCreate = async (data: FormDishDto) => {
    await QueryFactory.DishQuery.Client().create(
      data.name,
      data.ingredients,
      data.price,
      data.tags,
      createFileParameter(data.photo),
    );
    setQuery({ page: 1 });
    dishesQuery.refetch();
  };

  const handleOnDailyDishRemoved = async (date: Date, dishId: number) => {
    removeDishFromDailyMenu.mutate({ date, dishId });
  };

  const handleDailyDishOrderChanged = (
    date: Date,
    dishId: number,
    newSortNumber: number,
  ) => {
    changeOrderSortingFrDailyMenu.mutate({
      date,
      dishId,
      newSortIndex: newSortNumber,
    });
  };

  const memoizedTable = useMemo(
    () => (
      <AppTable
        table={table}
        customCellProps={getCustomCellProps}
        customRowProps={getCustomRowProps}
      />
    ),
    [table, dishesQuery.data, getCustomCellProps, getCustomRowProps],
  );

  return (
    <div className={styles.root}>
      <div className={styles.dishes}>
        <div className={styles.dishesHeader}>
          <div className={styles.dishesHeaderTitle}>{t('Dishes.Title')}</div>
          <Input
            className={styles.dishesHeaderSearch}
            placeholder={t('Dishes.Search')}
            defaultValue={query.search ?? undefined}
            onChange={(e) => setQuery({ search: e.target.value, page: 1 })}
            showEmptyErrorOrHint={false}
          />
        </div>
        <div className={styles.dishesTable}>
          {memoizedTable}
          <TablePagination
            page={query.page}
            perPage={query.perPage}
            totalCount={dishesQuery.data?.totalCount ?? 0}
            changePagination={setQuery}
          />
        </div>
        <FloatingButton
          onClick={() => setCreateDishPopupOpened(true)}
          className={styles.dishesCreateButton}
        >
          <CreateDishIcon />
        </FloatingButton>
        {createDishPopupOpened && (
          <CreateOrEditDishPopup
            title={t('Dishes.Create.Title')}
            opened={createDishPopupOpened}
            onSubmit={handleOnDishCreate}
            onClose={() => setCreateDishPopupOpened(false)}
          />
        )}
      </div>
      <DailyMenu
        onDrop={handleOnDrop}
        dragActive={dragStarted}
        onDishRemoved={handleOnDailyDishRemoved}
        onOrderChanged={handleDailyDishOrderChanged}
      />
    </div>
  );
};
