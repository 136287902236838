import { BlobImagePreview } from 'components/preview/BlobImagePreview';
import { CloseButton } from 'components/uikit/CloseButton';
import { createFileParameter } from 'helpers/file-helper';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import Resizer from 'react-image-file-resizer';
import { QueryFactory } from 'services/api';
import { ImagePreview } from '../../preview/ImagePreview';

const styles = require('./FileInput.module.scss');

type Props = {
  value?: File | null | string;
  description: string;
  errorText?: string;
  onChange: (file: File | null) => void;
  onPreviewLoaded?: (
    imageRef: React.RefObject<HTMLImageElement | null>,
  ) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
};

type UploaderProps = {
  value?: null | string;
  description: string;
  errorText?: string;
  onChange: (guid: string | null) => void;
  onPreviewLoaded?: (
    imageRef: React.RefObject<HTMLImageElement | null>,
  ) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
};

export const FileInputUploader = React.forwardRef<
  HTMLInputElement,
  UploaderProps
>(function (props, ref) {
  return (
    <FileInput
      ref={ref}
      {...props}
      onChange={async (file) => {
        const result = await QueryFactory.FileQuery.Client().upload(
          createFileParameter(file),
        );
        props.onChange(result.id);
      }}
    />
  );
});

export const FileInput = React.forwardRef<HTMLInputElement, Props>(function (
  props,
  ref,
) {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: 'image/jpeg, image/png',
    maxFiles: 1,
    onDropAccepted: (acceptedFiles) => {
      if (acceptedFiles.length > 1) {
        return;
      }
      const file = acceptedFiles[0];
      resizeImage(file);
    },
  });

  const imageMinSizeInPx = 600;
  const imageMaxSizeInPx = 1936;
  const imageQuality = 100;
  const imageRotation = 0;
  const imageOutputType = 'file';
  const imageCompressFormat = 'JPEG';

  const resizeImage = (file: File) => {
    Resizer.imageFileResizer(
      file,
      imageMaxSizeInPx,
      imageMaxSizeInPx,
      imageCompressFormat,
      imageQuality,
      imageRotation,
      (newPhoto) => {
        props.onChange(newPhoto as File);
      },
      imageOutputType,
      imageMinSizeInPx,
      imageMinSizeInPx,
    );
  };

  const handleFileRemoved = () => {
    props.onChange(null);
  };

  const fileSelected = !!props.value;

  return (
    <div className={styles.fileInputContent} ref={ref}>
      <div
        {...getRootProps()}
        className={styles.fileInput}
        data-hidden={fileSelected}
      >
        <input {...getInputProps()} ref={props.inputRef} />
        <div
          data-active={isDragActive}
          data-accept={isDragAccept}
          data-reject={isDragReject}
          className={styles.fileInputDropZone}
        >
          {props.description}
        </div>
      </div>
      <div className={styles.fileInputPreview} data-hidden={!fileSelected}>
        {props.value instanceof File ? (
          <BlobImagePreview
            onLoaded={props.onPreviewLoaded}
            photo={props.value}
          />
        ) : (
          <ImagePreview photoId={props.value} />
        )}
        <CloseButton onClick={handleFileRemoved} />
      </div>
      <span className={styles.fileInputPreviewError}>{props.errorText}</span>
    </div>
  );
});
