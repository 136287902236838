import React from 'react';
import { NavLink } from 'react-router-dom';
import { appVersion } from 'application/constants/env-variables';
import { Routes } from 'application/constants/routes';
import { MenuItem } from './MenuItem';
import { useScopedTranslation } from '../../application/localization/useScopedTranslation';
import styles from './Menu.module.scss';

export const Menu: React.FC = (props) => {
  const { t } = useScopedTranslation('Menu');
  return (
    <div className={styles.menuContainer}>
      <div className={styles.menuItems}>
        <NavLink
          to={Routes.Authorized.Orders}
          className={styles.menuItemsLink}
          activeClassName={styles.menuItemsLinkActive}
        >
          <MenuItem
            className={styles.menuItemIconOrders}
            caption={t('Orders')}
          />
        </NavLink>
        <NavLink
          to={Routes.Authorized.Dishes}
          className={styles.menuItemsLink}
          activeClassName={styles.menuItemsLinkActive}
        >
          <MenuItem
            className={styles.menuItemIconDishes}
            caption={t('Dishes')}
          />
        </NavLink>
        <NavLink
          to={Routes.Authorized.AboutUs}
          className={styles.menuItemsLink}
          activeClassName={styles.menuItemsLinkActive}
        >
          <MenuItem
            className={styles.menuItemIconAboutUs}
            caption={t('AboutUs')}
          />
        </NavLink>

        <NavLink
          to={Routes.Authorized.Settings}
          className={styles.menuItemsLink}
          activeClassName={styles.menuItemsLinkActive}
        >
          <MenuItem
            className={styles.menuItemIconSettings}
            caption={t('Settings')}
          />
        </NavLink>
        <div className={styles.menuItemsVersion}>{appVersion()}</div>
      </div>
      <div className={styles.menuContent}>{props.children}</div>
    </div>
  );
};
