import {
  fallbackLng,
  Language,
  languages,
} from 'application/localization/locales';
import { changeLanguage } from 'application/localization/localization';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Style from './languageSwitcher.module.css';
import { ReactComponent as DeLang } from 'assets/img/language/language_de.svg';
import { ReactComponent as EnLang } from 'assets/img/language/language_en.svg';

const resources: { [key in Language]: any } = {
  de: <DeLang />,
  en: <EnLang />,
};

export const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();

  const onClick = useCallback(async () => {
    const currentLanguageIndex = languages.findIndex(
      (x) => x === i18n.language,
    );
    const nextLanguageIndex =
      currentLanguageIndex + 1 >= languages.length
        ? 0
        : currentLanguageIndex + 1;

    await changeLanguage(languages[nextLanguageIndex]);
  }, [i18n.language]);

  const resource = useMemo(() => {
    return (languages.map((x) => x.toString()).includes(i18n.language)
      ? i18n.language
      : fallbackLng) as Language;
  }, [i18n.language]);

  return (
    <div onClick={onClick} className={Style.container}>
      {resources[resource]}
    </div>
  );
};
