import clsx from 'clsx';
import React, { FC } from 'react';
import { SignUrlImage } from '../sign-url/SignUrlImage';

const styles = require('./Preview.module.scss');

type Props = {
  url?: string | null;
  photoId?: string | null;
  fallback?: string;
  className?: string;
  imageClassName?: string;
  width?: number;
  height?: number;
};

export const ImagePreview: FC<Props> = ({
  className,
  url,
  fallback,
  imageClassName,
  photoId,
  width,
  height,
}) => {
  return (
    <div className={clsx(styles.preview, className)}>
      <SignUrlImage
        photoId={photoId}
        url={url}
        fallback={fallback}
        className={clsx(styles.previewImage, imageClassName)}
        width={width}
        height={height}
      />
    </div>
  );
};
