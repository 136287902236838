import { combineReducers } from 'redux';
import { GlobalState } from './types';
import { CombinedState, createAction, Reducer } from '@reduxjs/toolkit';
import { themeSlice } from './theme/theme-slice';

const combinedReducer: Reducer<CombinedState<GlobalState>> = combineReducers({
  theme: themeSlice.reducer,
});
export const logoutAction = createAction('logout');
export const rootReducer: Reducer<CombinedState<GlobalState>> = (
  state,
  action,
) => {
  if (action.type === logoutAction.type) {
    state = undefined;
  }

  return combinedReducer(state, action);
};
