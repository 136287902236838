import { TextArea } from 'components/uikit/inputs/TextArea';
import { convertToErrorString } from 'helpers/form/useErrorHandler';
import React, { useState } from 'react';

const styles = require('pages/authorized/aboutUs/AboutUs.module.scss');

export const AboutUsTextAreaElement: React.FC<{
  name: string;
  defaultValue: string;
  onSave: (newValue: string) => void;
  minRows?: number;
}> = (props) => {
  const [isInEditing, setIsInEditing] = useState(false);
  const [fieldValue, setFieldValue] = useState(props.defaultValue);
  const [oldFieldValue, setOldFieldValue] = useState(props.defaultValue);

  const handleValueUpdate = async () => {
    try {
      props.onSave(fieldValue);
      setFieldValue(fieldValue);
      setOldFieldValue(fieldValue);
      setIsInEditing(false);
    } catch (e) {
      alert(convertToErrorString(e));
    }
  };

  const buttons = isInEditing ? (
    <>
      <div
        data-type={'confirm'}
        className={styles.fieldButton}
        onClick={async () => {
          await handleValueUpdate();
        }}
      />
      <div
        data-type={'cancel'}
        className={styles.fieldButton}
        onClick={() => {
          setIsInEditing(false);
          setFieldValue(oldFieldValue);
        }}
      />
    </>
  ) : (
    <div
      data-type={'edit'}
      className={styles.fieldButton}
      onClick={() => setIsInEditing(true)}
    />
  );

  return (
    <div className={styles.field}>
      <div className={styles.fieldTitle}>{props.name}</div>
      {isInEditing ? (
        <div className={styles.fieldTextbox}>
          <TextArea
            className={styles.fieldTextbox}
            onChange={(event) => setFieldValue(event.target.value)}
            value={fieldValue}
            minRows={props.minRows}
          />
        </div>
      ) : (
        <div className={styles.fieldTextbox}>{fieldValue}</div>
      )}
      <div className={styles.fieldWorkingHoursButtons}>{buttons}</div>
    </div>
  );
};
