import clsx from 'clsx';
import NoDishPhoto from 'pages/authorized/dishes/NoDishPhoto.png';
import React, { FC, ReactNode } from 'react';
import { ImagePreview } from '../../../../components/preview/ImagePreview';
import { QueryFactory } from '../../../../services/api';

const styles = require('./DailyDish.module.scss');

type DailyDishProps = {
  id: number;
  photoId?: string | null;
  name: string;
  disabled?: boolean;
  className?: string;
  nameClassName?: string;
  onRemove?: (dishId: number) => void;
  onOrderUp?: () => void;
  additionalInfo?: ReactNode;
  enableOrdering?: boolean;
};

export const DailyDish: FC<DailyDishProps> = ({
  id,
  photoId,
  name,
  disabled,
  className,
  onRemove,
  onOrderUp,
  nameClassName,
  additionalInfo,
  enableOrdering,
}) => {
  return (
    <div className={clsx(styles.dailyDish, className)} data-disabled={disabled}>
      {enableOrdering && (
        <div
          className={clsx({
            [styles.dailyDishUp]: true,
            [styles.dailyDishUpHidden]: !onOrderUp,
          })}
          onClick={onOrderUp}
        />
      )}
      <ImagePreview
        photoId={photoId}
        fallback={NoDishPhoto}
        className={styles.dailyDishPreview}
        width={51}
      />
      <div className={clsx(styles.dailyDishName, nameClassName)}>{name}</div>
      {onRemove && (
        <div
          className={styles.dailyDishRemove}
          onClick={() => onRemove?.(id)}
        />
      )}
      {additionalInfo && (
        <div className={styles.dailyDishInfo}>{additionalInfo}</div>
      )}
    </div>
  );
};
