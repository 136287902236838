import React, { useCallback, useMemo } from 'react';
import { QueryFactory } from 'services/api';
import {
  DayOfWeek,
  IPatchSettingsDto,
  PatchSettingsDto,
} from 'services/api/api-client';
import {
  localFormat,
  parseTimeToSeconds,
  secondsToString,
} from '../../../helpers/date-helpers';
import { useScopedTranslation } from '../../../application/localization/useScopedTranslation';
import { Input } from '../../../components/uikit/inputs/Input';
import { requiredRule } from '../../../helpers/form/react-hook-form-helper';
import { Loading } from '../../../components/uikit/suspense/Loading';
import { useAdvancedForm } from '../../../helpers/form/advanced-form';
import { HorizontalField } from '../../../components/uikit/HorizontalField';
import { Button } from '../../../components/uikit/buttons/Button';
import { CheckBox } from '../../../components/uikit/CheckBox';
import { addDays } from 'date-fns';
import { getAllTimezones } from 'countries-and-timezones';
import { HookFormComboBoxInput } from '../../../components/uikit/inputs/dropdown/HookFormComboBoxInput';

const timezones = Object.keys(getAllTimezones());
const styles = require('./Settings.module.scss');

export const Settings: React.FC = () => {
  const { t, i18n } = useScopedTranslation('Settings');

  const query = QueryFactory.SettingsQuery.useGetForAdminQuery();
  const data = query.data;

  const form = useAdvancedForm<IPatchSettingsDto>(
    useCallback(async (data) => {
      await QueryFactory.SettingsQuery.Client().patch(
        new PatchSettingsDto({ ...data, aboutUs: undefined }),
      );
      await query.refetch();
    }, []),
    {
      defaultValues: useMemo(() => {
        return data
          ? {
              ...data,
              orderTimeLimitInSecondsSinceMidnight: secondsToString(
                data.orderTimeLimitInSecondsSinceMidnight,
              ) as any,
              orderEndTimeInSecondsSinceMidnight: secondsToString(
                data.orderEndTimeInSecondsSinceMidnight,
              ) as any,
              orderStartTimeInSecondsSinceMidnight: secondsToString(
                data.orderStartTimeInSecondsSinceMidnight,
              ) as any,
            }
          : undefined;
      }, [query.data]),
    },
  );
  const localizedWeekDays = useMemo(() => {
    const sunday = new Date(2022, 3, 3);
    return {
      [DayOfWeek.Monday]: localFormat(addDays(sunday, 1), 'eeee'),
      [DayOfWeek.Tuesday]: localFormat(addDays(sunday, 2), 'eeee'),
      [DayOfWeek.Wednesday]: localFormat(addDays(sunday, 3), 'eeee'),
      [DayOfWeek.Thursday]: localFormat(addDays(sunday, 4), 'eeee'),
      [DayOfWeek.Friday]: localFormat(addDays(sunday, 5), 'eeee'),
      [DayOfWeek.Saturday]: localFormat(addDays(sunday, 6), 'eeee'),
      [DayOfWeek.Sunday]: localFormat(addDays(sunday, 0), 'eeee'),
    } as Record<DayOfWeek, string>;
  }, [i18n.language]);
  return (
    <div className={styles.settings}>
      <div className={styles.settingsHeader}>
        <div className={styles.settingsHeaderTitle}>{t('Title')}</div>
      </div>
      <div className={styles.settingsContents}>
        <Loading loading={form.formState.isSubmitting}>
          <form onSubmit={form.handleSubmitDefault}>
            <div className={styles.fieldsBlock}>
              <HorizontalField title={t('Timezone')}>
                <HookFormComboBoxInput
                  options={timezones}
                  name={'timezone'}
                  allowFreeform={true}
                  control={form.control}
                />
                {/*<Input*/}
                {/*  {...form.register('timezone', { ...requiredRule() })}*/}
                {/*  errorText={form.formState.errors.timezone?.message}*/}
                {/*  placeholder={t('Timezone.Placeholder')}*/}
                {/*/>*/}
              </HorizontalField>
              <div className={styles.separator} />
              <HorizontalField title={t('AcceptOrdersTill')}>
                <Input
                  {...form.register('orderTimeLimitInSecondsSinceMidnight', {
                    ...requiredRule(),
                    setValueAs: (v) => parseTimeToSeconds(v),
                  })}
                  type={'time'}
                  errorText={
                    form.formState.errors.orderTimeLimitInSecondsSinceMidnight
                      ?.message
                  }
                />
              </HorizontalField>
              <div className={styles.separator} />
              <HorizontalField title={t('StartTime')}>
                <Input
                  {...form.register('orderStartTimeInSecondsSinceMidnight', {
                    ...requiredRule(),
                    setValueAs: (v) => parseTimeToSeconds(v),
                  })}
                  type={'time'}
                  errorText={
                    form.formState.errors.orderStartTimeInSecondsSinceMidnight
                      ?.message
                  }
                />
              </HorizontalField>
              <div className={styles.separator} />
              <HorizontalField title={t('EndTime')}>
                <Input
                  {...form.register('orderEndTimeInSecondsSinceMidnight', {
                    ...requiredRule(),
                    setValueAs: (v) => parseTimeToSeconds(v),
                  })}
                  type={'time'}
                  errorText={
                    form.formState.errors.orderEndTimeInSecondsSinceMidnight
                      ?.message
                  }
                />
              </HorizontalField>
              <div className={styles.separator} />
              <HorizontalField title={t('WorkingDays')}>
                <div className={styles.workDaysWrapper}>
                  <div
                    className={styles.workDaysSelectAll}
                    onClick={() => {
                      form.setValue('workingDays', Object.values(DayOfWeek));
                    }}
                  >
                    {t('WorkingDays_SelectAll')}
                  </div>
                  {(Object.keys(localizedWeekDays) as DayOfWeek[]).map(
                    (dayOfWeek, index) => {
                      return (
                        <CheckBox
                          {...form.register('workingDays')}
                          title={localizedWeekDays[dayOfWeek]}
                          value={dayOfWeek}
                          disabled={false}
                        />
                      );
                    },
                  )}
                </div>
              </HorizontalField>
            </div>
            <div className={styles.buttonsRow}>
              <Button type={'submit'} title={t('SaveButton')} />
            </div>
          </form>
        </Loading>
      </div>
    </div>
  );
};
