import { Input } from 'components/uikit/inputs/Input';
import { convertToErrorString } from 'helpers/form/useErrorHandler';
import React, { useState } from 'react';

const styles = require('pages/authorized/aboutUs/AboutUs.module.scss');

export const AboutUsInputElement: React.FC<{
  name: string;
  defaultValue: string;
  onSave: (newValue: string) => Promise<void>;
  hint?: string;
  type?: string;
}> = (props) => {
  const [isInEditing, setIsInEditing] = useState(false);
  const [fieldValue, setFieldValue] = useState(props.defaultValue);
  const [oldFieldValue, setOldFieldValue] = useState(props.defaultValue);

  const handleValueUpdate = async () => {
    try {
      await props.onSave(fieldValue);
      setFieldValue(fieldValue);
      setOldFieldValue(fieldValue);
      setIsInEditing(false);
    } catch (e) {
      alert(convertToErrorString(e));
    }
  };

  const buttons = isInEditing ? (
    <>
      <div
        data-type={'confirm'}
        className={styles.fieldButton}
        onClick={async () => {
          await handleValueUpdate();
        }}
      />
      <div
        data-type={'cancel'}
        className={styles.fieldButton}
        onClick={() => {
          setIsInEditing(false);
          setFieldValue(oldFieldValue);
        }}
      />
    </>
  ) : (
    <div
      data-type={'edit'}
      className={styles.fieldButton}
      onClick={() => setIsInEditing(true)}
    />
  );

  return (
    <div className={styles.field}>
      <div className={styles.fieldTitle}>{props.name}</div>
      {isInEditing ? (
        <Input
          className={styles.fieldTextbox}
          onChange={(event) => setFieldValue(event.target.value)}
          onEnterPressed={handleValueUpdate}
          value={fieldValue}
          showEmptyErrorOrHint={!!props.hint}
          helperText={props.hint}
          type={props.type}
        />
      ) : (
        <div className={styles.fieldTextbox}>
          {fieldValue?.startsWith('http') ? (
            <a href={fieldValue}>{fieldValue}</a>
          ) : (
            fieldValue
          )}
        </div>
      )}
      <div className={styles.fieldButtons}>{buttons}</div>
    </div>
  );
};
