import React, { FC } from 'react';

const styles = require('../Orders.module.scss');

type Props = {
  name?: string;
  phoneNumber?: string;
};

export const CustomerCell: FC<Props> = ({ name, phoneNumber }) => {
  return (
    <div className={styles.customer}>
      <p>{name}</p>
      <p>{phoneNumber}</p>
    </div>
  );
};
