import clsx from 'clsx';
import React, { ReactNode } from 'react';
const styles = require('./FloatingButton.module.scss');

type Props = {
  onClick: () => void;
  className?: string;
  children?: ReactNode;
};

export const FloatingButton: React.FC<Props> = (props) => {
  return (
    <div
      className={clsx(styles.floatingButton, props.className)}
      onClick={props.onClick}
    >
      {props.children}
    </div>
  );
};
