import { Tag } from 'components/uikit/inputs/tags/Tags';
import { weekDayTags } from 'components/uikit/inputs/tags/values';
import React, { FC } from 'react';
import { localFormat } from 'helpers/date-helpers';
import { getDay } from 'date-fns';

const styles = require('../Orders.module.scss');

type Props = {
  date: Date;
};

export const PickupTimeCell: FC<Props> = (props) => {
  const weekDayTag = weekDayTags.find(
    (x) => Number(x.key) === getDay(props.date),
  );

  return (
    <div className={styles.pickupTime}>
      {!!weekDayTag && (
        <Tag
          {...weekDayTag}
          selected={true}
          interactable={false}
          name={localFormat(props.date, 'EEE')}
          className={styles.pickupTimeTag}
        />
      )}
      <div
        className={styles.ordersTableNowrap}
        title={localFormat(props.date, 'PPp')}
      >
        {localFormat(props.date, 'HH:mm')}
      </div>
    </div>
  );
};
