import { Input } from 'components/uikit/inputs/Input';
import { TextArea } from 'components/uikit/inputs/TextArea';
import { convertToErrorString } from 'helpers/form/useErrorHandler';
import React, { useState } from 'react';
import {
  BranchInfoEditableDto,
  IBranchInfoEditableDto,
} from 'services/api/api-client';

const styles = require('pages/authorized/aboutUs/AboutUs.module.scss');

export const AboutUsHoursInputElement: React.FC<{
  name: string;
  defaultValue: BranchInfoEditableDto;
  onSave: (newValue: IBranchInfoEditableDto) => void;
}> = (props) => {
  const [isInEditing, setIsInEditing] = useState(false);
  const [fieldValue, setFieldValue] = useState<IBranchInfoEditableDto>(
    props.defaultValue,
  );
  const [oldFieldValue, setOldFieldValue] = useState<IBranchInfoEditableDto>(
    props.defaultValue,
  );

  const handleValueUpdate = async () => {
    try {
      props.onSave(fieldValue);
      setFieldValue(fieldValue);
      setOldFieldValue(fieldValue);
      setIsInEditing(false);
    } catch (e) {
      alert(convertToErrorString(e));
    }
  };

  const buttons = isInEditing ? (
    <>
      <div
        data-type={'confirm'}
        className={styles.fieldButton}
        onClick={async () => {
          await handleValueUpdate();
        }}
      />
      <div
        data-type={'cancel'}
        className={styles.fieldButton}
        onClick={() => {
          setIsInEditing(false);
          setFieldValue(oldFieldValue);
        }}
      />
    </>
  ) : (
    <div
      data-type={'edit'}
      className={styles.fieldButton}
      onClick={() => setIsInEditing(true)}
    />
  );

  return (
    <div className={styles.field}>
      <div className={styles.fieldTitle}>{props.name}</div>
      {isInEditing ? (
        <div className={styles.fieldWorkingHours}>
          <div className={styles.fieldWorkingHoursTextbox}>
            <Input
              onChange={(event) => {
                const newValue = fieldValue;
                newValue.workingHoursTitle1 = event.target.value;
                setFieldValue({ ...newValue });
              }}
              value={fieldValue.workingHoursTitle1}
              showEmptyErrorOrHint={false}
            />
          </div>
          <div className={styles.fieldWorkingHoursTextbox}>
            <TextArea
              onChange={(event) => {
                const newValue = fieldValue;
                newValue.workingHoursDescription1 = event.target.value;
                setFieldValue({ ...newValue });
              }}
              value={fieldValue.workingHoursDescription1}
              minRows={2}
            />
          </div>
          <div className={styles.fieldWorkingHoursTextbox}>
            <Input
              onChange={(event) => {
                const newValue = fieldValue;
                newValue.workingHoursTitle2 = event.target.value;
                setFieldValue({ ...newValue });
              }}
              value={fieldValue.workingHoursTitle2}
              showEmptyErrorOrHint={false}
            />
          </div>
          <div className={styles.fieldWorkingHoursTextbox}>
            <TextArea
              onChange={(event) => {
                const newValue = fieldValue;
                newValue.workingHoursDescription2 = event.target.value;
                setFieldValue({ ...newValue });
              }}
              value={fieldValue.workingHoursDescription2}
              minRows={2}
            />
          </div>
        </div>
      ) : (
        <div className={styles.fieldWorkingHours}>
          <div className={styles.fieldWorkingHoursTextboxDays}>
            {fieldValue.workingHoursTitle1}
          </div>
          <div className={styles.fieldWorkingHoursTextboxHours}>
            {fieldValue.workingHoursDescription1}
          </div>
          <div className={styles.fieldWorkingHoursTextboxDays}>
            {fieldValue.workingHoursTitle2}
          </div>
          <div className={styles.fieldWorkingHoursTextboxHours}>
            {fieldValue.workingHoursDescription2}
          </div>
        </div>
      )}
      <div className={styles.fieldWorkingHoursButtons}>{buttons}</div>
    </div>
  );
};
