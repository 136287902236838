import clsx from 'clsx';
import React from 'react';
const styles = require('./Menu.module.scss');

type Props = {
  caption: string;
  className: string;
};

export const MenuItem: React.FC<Props> = (props) => {
  return (
    <div className={styles.menuItem}>
      <div className={clsx(styles.menuItemIcon, props.className)} />
      <div className={styles.menuItemCaption}>{props.caption}</div>
    </div>
  );
};
