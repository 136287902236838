import clsx from 'clsx';
import React, { FC, RefObject, useEffect, useRef, useState } from 'react';

const styles = require('./Preview.module.scss');

type Props = {
  photo?: File | Blob | null;
  fallback?: string;
  className?: string;
  onLoaded?: (ref: RefObject<HTMLImageElement | null>) => void;
};

export const BlobImagePreview: FC<Props> = ({
  className,
  photo,
  onLoaded,
  fallback,
}) => {
  const [preview, setPreview] = useState<string | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    function cleanupPreview() {
      setPreview((prevValue) => {
        if (prevValue) {
          URL.revokeObjectURL(prevValue);
        }
        return null;
      });
    }

    if (photo) {
      setPreview(URL.createObjectURL(photo));
    } else {
      cleanupPreview();
    }
    return () => {
      cleanupPreview();
    };
  }, [photo]);

  return (
    <div className={clsx(styles.preview, className)}>
      <img
        ref={imageRef}
        src={preview ?? fallback}
        className={styles.previewImage}
        onLoad={() => onLoaded?.(imageRef)}
      />
    </div>
  );
};
