export const Routes = {
  Unauthorized: {
    Login: '/login',
  },
  Authorized: {
    Root: '/',
    Dishes: '/dishes',
    Orders: '/orders',
    Products: '/products',
    AboutUs: '/about-us',
    Settings: '/settings',
    CreateProduct: '/products/create',
  },
};
