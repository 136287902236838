import React from 'react';
import { ReactComponent as BeefIcon } from './icons/Beef.svg';
import { ReactComponent as BeefBigIcon } from './icons/BeefBig.svg';
import { ReactComponent as ChickenIcon } from './icons/Chicken.svg';
import { ReactComponent as ChickenBigIcon } from './icons/ChickenBig.svg';
import { ReactComponent as HotIcon } from './icons/Hot.svg';
import { ReactComponent as HotBigIcon } from './icons/HotBig.svg';
import { ReactComponent as PorkIcon } from './icons/Pork.svg';
import { ReactComponent as PorkBigIcon } from './icons/PorkBig.svg';
import { ReactComponent as VeganIcon } from './icons/Vegan.svg';
import { ReactComponent as VeganBigIcon } from './icons/VeganBig.svg';
import { ReactComponent as BunIcon } from './icons/Bun.svg';
import { ReactComponent as BunBigIcon } from './icons/BunBig.svg';
import { TagType } from './Tags';

export const foodTags: TagType[] = [
  {
    key: 'Pork',
    name: 'Tags.Pork',
    icon: <PorkIcon />,
    bigIcon: <PorkBigIcon />,
    color: 'red',
  },
  {
    key: 'Beef',
    name: 'Tags.Beef',
    icon: <BeefIcon />,
    bigIcon: <BeefBigIcon />,
    color: 'red',
  },
  {
    key: 'Chicken',
    name: 'Tags.Chicken',
    icon: <ChickenIcon />,
    bigIcon: <ChickenBigIcon />,
    color: 'red',
  },
  {
    key: 'Vegan',
    name: 'Tags.Vegan',
    icon: <VeganIcon />,
    bigIcon: <VeganBigIcon />,
    color: 'green',
  },
  {
    key: 'Hot',
    name: 'Tags.Hot',
    icon: <HotIcon />,
    bigIcon: <HotBigIcon />,
    color: 'orange',
  },
  {
    key: 'Bun',
    name: 'Tags.Bun',
    icon: <BunIcon />,
    bigIcon: <BunBigIcon />,
    color: 'orange',
  },
];

export const weekDayTags: TagType[] = [
  {
    key: '2',
    color: 'red',
  },
  {
    key: '3',
    color: 'orange',
  },
  {
    key: '4',
    color: 'green',
  },
  {
    key: '5',
    color: 'blue',
  },
];
