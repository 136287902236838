import { OwnModal } from 'components/modal/OwnModal';
import { Button, ButtonColor } from 'components/uikit/buttons/Button';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

const styles = require('./ConfirmationModal.module.scss');

type Props = {
  title: string;
  text: string;
  opened: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

export const ConfirmationModal: FC<Props> = ({
  text,
  title,
  opened,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <OwnModal title={title} opened={opened} onClose={onClose}>
      <div className={styles.confirmationModalText}>{text}</div>
      <div className={styles.confirmationModalButtons}>
        <Button
          onClick={onClose}
          title={t('Buttons.Cancel')}
          color={ButtonColor.Secondary}
        />
        <Button onClick={onConfirm} title={t('Buttons.Delete')} />
      </div>
    </OwnModal>
  );
};
