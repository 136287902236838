import { useAppDispatch } from 'application/redux-store/root-store';
import { LanguageSwitcher } from 'components/languageSwitcher/languageSwitcher.component';
import { ReceiptsCounter } from 'components/receiptsCounter/ReceiptsCounter';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from './Logo.svg';
import { setAuthData } from '../../helpers/interceptors/auth/auth-interceptor';
import { useScopedTranslation } from '../../application/localization/useScopedTranslation';

const styles = require('./Header.module.scss');

type Props = {
  canLogout: boolean;
};

export const Header: React.FC<Props> = ({ canLogout }) => {
  const dispatch = useAppDispatch();
  const { t } = useScopedTranslation('Header');

  const handleOnLogoutClick = () => {
    setAuthData(null);
  };

  return (
    <div className={styles.header}>
      <Logo />
      <div className={styles.headerTitle}>{t('Title')}</div>
      {canLogout && <ReceiptsCounter />}
      <LanguageSwitcher />
      {canLogout && (
        <div className={styles.headerLogout} onClick={handleOnLogoutClick}>
          {t('Logout')}
        </div>
      )}
    </div>
  );
};
