import { appVersion } from 'application/constants/env-variables';
import { useScopedTranslation } from 'application/localization/useScopedTranslation';
import { Button } from 'components/uikit/buttons/Button';
import { Field } from 'components/uikit/Field';
import { FormError } from 'components/uikit/FormError';
import { Input } from 'components/uikit/inputs/Input';
import { Loading } from 'components/uikit/suspense/Loading';
import { useAdvancedForm } from 'helpers/form/advanced-form';
import { requiredRule } from 'helpers/form/react-hook-form-helper';
import Logger from 'js-logger';
import React, { useCallback } from 'react';
import { sendLoginRequest } from 'services/auth-client';
import Grid from '@material-ui/core/Grid';
import { setAuthData } from '../../../helpers/interceptors/auth/auth-interceptor';
const styles = require('./LoginPage.module.scss');

type LoginForm = {
  login: string;
  password: string;
};

export const LoginPage: React.FC = () => {
  const { t } = useScopedTranslation('Page.Login');
  const form = useAdvancedForm<LoginForm>(
    useCallback(async (data) => {
      const response = await sendLoginRequest(data.login, data.password);
      setAuthData(response);
      Logger.info('Logged in successfully');
    }, []),
  );
  return (
    <Grid
      container
      justify={'center'}
      alignItems={'center'}
      direction={'column'}
      className={styles.loginWrapper}
    >
      <Loading loading={form.formState.isSubmitting}>
        <div className={styles.login}>
          <Grid item>
            <div className={styles.loginTitle}>{t('Title')}</div>
            <form
              onSubmit={form.handleSubmitDefault}
              className={styles.loginForm}
            >
              <Field title={t('login_field')}>
                <Input
                  {...form.register('login', { ...requiredRule() })}
                  errorText={form.formState.errors.login?.message}
                  autoComplete={'username'}
                />
              </Field>
              <Field title={t('password_field')}>
                <Input
                  type={'password'}
                  {...form.register('password', { ...requiredRule() })}
                  errorText={form.formState.errors.password?.message}
                  autoComplete={'current-password'}
                />
              </Field>
              <FormError>{form.overallError}</FormError>
              <Button
                type={'submit'}
                title={t('login_button')}
                className={styles.loginSubmit}
              />
            </form>
          </Grid>
        </div>
      </Loading>
      <div className={styles.loginVersion}>Version: {appVersion()}</div>
    </Grid>
  );
};
