import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { ConfirmationModal } from 'components/modal/ConfirmationModal';
import { OwnModal } from 'components/modal/OwnModal';
import { Loading } from 'components/uikit/suspense/Loading';
import {
  CreateOrEditStoryPopup,
  FormStoryDto,
} from 'pages/authorized/dishes/stories/CreateOrEditStoryPopup';
import React, { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IStoryDto, StoryQuery } from 'services/api/api-client';
import { ReactComponent as PlusIcon } from 'pages/authorized/dishes/icons/Plus.svg';
import { ReactComponent as EditIcon } from 'pages/authorized/dishes/icons/EditDish.svg';
import { ReactComponent as DeleteIcon } from 'pages/authorized/dishes/icons/DeleteDish.svg';
import { useQueryClient } from 'react-query';
import { QueryFactory } from '../../../../services/api';
import { ImagePreview } from 'components/preview/ImagePreview';

const styles = require('pages/authorized/dishes/stories/StoriesPopup.module.scss');

type Props = {
  dishId: number;
  opened: boolean;
  onClose: () => void;
};

export const StoriesPopup: FC<Props> = ({ dishId, opened, onClose }) => {
  const { t } = useTranslation();
  const [createOpened, setCreateOpened] = useState(false);
  const storiesQuery = StoryQuery.useGetAllQuery(dishId);
  const queryClient = useQueryClient();

  const invalidateQueries = () => {
    storiesQuery.refetch();
    queryClient.invalidateQueries(QueryFactory.DishQuery.searchQueryKey());
  };

  const handleCreateStory = async (data: FormStoryDto) => {
    await StoryQuery.Client().create(
      dishId,
      data.name,
      data.description,
      data.photo && data.photo instanceof File
        ? { data: data.photo, fileName: data.photo.name }
        : null,
      data.tag,
      data.link,
      data.linkName,
    );
    setCreateOpened(false);
    invalidateQueries();
  };

  const handleEditStory = async (data: FormStoryDto) => {
    await StoryQuery.Client().update(
      dishId,
      data.id,
      data.name,
      data.description,
      data.photo && data.photo instanceof File
        ? { data: data.photo, fileName: data.photo.name }
        : null,
      data.tag,
      data.link,
      data.linkName,
    );
    invalidateQueries();
  };

  const handleDeleteStory = async (storyId: number) => {
    await StoryQuery.Client().delete(dishId, storyId);
    invalidateQueries();
  };

  return (
    <OwnModal
      opened={opened}
      onClose={onClose}
      className={styles.storiesPopup}
      title={t('Dishes.Stories.Title')}
    >
      <Loading loading={storiesQuery.isLoading}>
        <List>
          {storiesQuery.data?.map((story) => (
            <Fragment key={story.id}>
              <StoryItem
                story={story}
                onEdit={handleEditStory}
                onDelete={handleDeleteStory}
              />
              <Divider />
            </Fragment>
          ))}
        </List>
        <div className={styles.storiesPopupStoryAdd}>
          <div
            onClick={() => setCreateOpened(true)}
            className={styles.storiesPopupStoryAddContent}
          >
            <PlusIcon />
            <div className={styles.storiesPopupStoryAddContentText}>
              {t('Dishes.Story.Create.Title')}
            </div>
          </div>
        </div>
        {createOpened && (
          <CreateOrEditStoryPopup
            opened={createOpened}
            title={t('Dishes.Story.Create.Title')}
            onSubmit={handleCreateStory}
            onClose={() => setCreateOpened(false)}
          />
        )}
      </Loading>
    </OwnModal>
  );
};

type StoryItemProps = {
  story: IStoryDto;
  onDelete: (storyId: number) => Promise<void>;
  onEdit: (form: FormStoryDto) => Promise<void>;
};

const StoryItem: FC<StoryItemProps> = ({ story, onDelete, onEdit }) => {
  const { t } = useTranslation();
  const [editOpened, setEditOpened] = useState(false);
  const [deleteOpened, setDeleteOpened] = useState(false);

  const formValues: FormStoryDto = {
    ...story,
    photo: story.photoId,
  };

  return (
    <>
      <ListItem>
        <ListItemIcon>
          <ImagePreview
            photoId={story.photoId}
            className={styles.storiesPopupStoryPreview}
          />
        </ListItemIcon>
        <ListItemText>
          <div className={styles.storiesPopupStoryText}>{story.name}</div>
        </ListItemText>
        <ListItemSecondaryAction>
          <EditIcon
            onClick={() => setEditOpened(true)}
            className={styles.storiesPopupStoryActionButton}
          />
          <DeleteIcon
            onClick={() => setDeleteOpened(true)}
            className={styles.storiesPopupStoryActionButton}
          />
        </ListItemSecondaryAction>
      </ListItem>
      {editOpened && (
        <CreateOrEditStoryPopup
          opened={editOpened}
          defaultValues={formValues}
          title={t('Dishes.Story.Edit.Title')}
          onSubmit={async (form) => {
            await onEdit(form);
            setEditOpened(false);
          }}
          onClose={() => setEditOpened(false)}
        />
      )}
      <ConfirmationModal
        opened={deleteOpened}
        text={t('Dishes.Story.Delete.Text')}
        title={t('Dishes.Story.Delete.Title')}
        onConfirm={async () => {
          await onDelete(story.id);
          setDeleteOpened(false);
        }}
        onClose={() => setDeleteOpened(false)}
      />
    </>
  );
};
