import { addWeeks, endOfWeek, startOfWeek } from 'date-fns';
import { DateParam, useQueryParam, withDefault } from 'use-query-params';
import React, { FC, useEffect } from 'react';
import clsx from 'clsx';
import { convertToShortDate } from 'helpers/date-helpers';

const styles = require('./DailyMenu.module.scss');

type WeekSwitcherProps = {
  onStartWeekChanged: (date: Date) => unknown;
  allowPast?: boolean;
};
export const WeekSwitcher: FC<WeekSwitcherProps> = (props) => {
  const currentWeekStart = startOfWeek(new Date());
  const [startWeek, setStartWeek] = useQueryParam(
    'weekDate',
    withDefault(DateParam, currentWeekStart),
  );
  const handleOnWeekChange = (direction: 'prev' | 'next') => {
    setStartWeek(addWeeks(startWeek, direction === 'prev' ? -1 : 1));
  };
  useEffect(() => {
    props.onStartWeekChanged(startWeek);
  }, [startWeek]);

  return (
    <div className={styles.dailyMenuWeek}>
      <div
        onClick={() => handleOnWeekChange('prev')}
        className={clsx(styles.dailyMenuWeekPrev, {
          [styles.dailyMenuWeekPrevHidden]:
            startWeek <= currentWeekStart && !props.allowPast,
        })}
      />
      <div className={styles.dailyMenuWeekTitle}>
        <div className={styles.dailyMenuWeekTitleName}></div>
        <div className={styles.dailyMenuWeekTitleDates}>
          {convertToShortDate(startWeek)} -{' '}
          {convertToShortDate(endOfWeek(startWeek))}
        </div>
      </div>
      <div
        onClick={() => handleOnWeekChange('next')}
        className={clsx(styles.dailyMenuWeekNext, {
          [styles.dailyMenuWeekNextHidden]:
            startWeek >= addWeeks(currentWeekStart, 2),
        })}
      />
    </div>
  );
};
