import i18n from 'i18next';
import { useEffect } from 'react';
import { DefaultValues, UseFormReturn } from 'react-hook-form';

/*
This hook is useful, when defaultValues of the Form become available AFTER form is initially rendered.
E.g. when defaultValues are loaded via react-query.
When defaultValues change, form fields are updated according to changed values.
 */
export function useResetFormWhenDataIsLoaded<T>(
  form: UseFormReturn<T>,
  defaultValues?: DefaultValues<T>,
) {
  useEffect(() => {
    if (defaultValues) {
      form.reset(defaultValues, { keepDefaultValues: true });
    }
  }, [defaultValues]);
}

export function requiredRule() {
  return {
    required: {
      value: true,
      message: i18n.t('uikit.inputs.required'),
    },
  };
}
