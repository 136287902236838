import React, { FC } from 'react';
import { QueryFactory } from '../../services/api';

type Props = Omit<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >,
  'src'
> & {
  /**
   *  Url to get the photo from
   *
   *  @remarks
   *  Used only when `photoId` is not set
   */
  url?: string | null;
  /**
   *  Photo id for `'/api/files/photo/{id}'`
   */
  photoId?: string | null;
  fallback?: string;
  width?: number;
  height?: number;
  usePixelRatio?: boolean;
};

export const SignUrlImage: FC<Props> = (props) => {
  const { id, url, fallback, usePixelRatio = true, ...restProps } = props;
  const cookieQuery = QueryFactory.SignUrlQuery.useSetSignatureCookieQuery({
    refetchIntervalInBackground: true,
    refetchInterval: 60 * 60 * 1000 /* once in 60 minutes*/,
  });

  let src;

  if (props.photoId) {
    src = QueryFactory.FileQuery.getPhotoUrl(
      props.photoId,
      usePixelRatio
        ? props.width && Math.round(props.width * window.devicePixelRatio)
        : props.width,
      usePixelRatio
        ? props.height && Math.round(props.height * window.devicePixelRatio)
        : props.height,
    );
  } else {
    src = url;
  }

  return (
    <img
      {...restProps}
      src={
        /*
          We show fallback if the `url` is null or if the Cookie query has failed.
          While Cookie query is loading (initial loading) we show nothing, because it will probably load fast
          and it doesn't make sense to show `fallback` (we might want to show `loading` later)
           */
        !src
          ? fallback
          : cookieQuery.isLoading
          ? ''
          : cookieQuery.isError
          ? fallback
          : src
      }
    />
  );
};
