import { Loading } from 'components/uikit/suspense/Loading';
import { localFormat } from 'helpers/date-helpers';
import { DailyDish } from 'pages/authorized/dishes/daily-menu/DailyDish';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryFactory } from 'services/api';
import { WeekSwitcher } from './WeekSwitcher';

const styles = require('./DailyMenu.module.scss');

type DailyMenuProps = {
  dragActive?: boolean;
  onDrop?: (e: React.DragEvent<HTMLDivElement>, date: Date) => void;
  onDishRemoved?: (date: Date, dishId: number) => void;
  onOrderChanged?: (date: Date, dishId: number, newIndex: number) => void;
};

export const DailyMenu: FC<DailyMenuProps> = ({
  onDrop,
  dragActive,
  onDishRemoved,
  onOrderChanged,
}) => {
  const { t } = useTranslation();
  const [startWeek, setStartWeek] = useState<Date | undefined>();
  const dailyMenuQuery = QueryFactory.DailyMenuQuery.useGetWeeklyMenuV2Query({
    startWeekDate: startWeek,
  });
  const menu = dailyMenuQuery.data;
  const loading = dailyMenuQuery.isLoading;

  return (
    <div
      className={styles.dailyMenu}
      onDragOver={(e) => !!onDrop && e.preventDefault()}
    >
      <WeekSwitcher onStartWeekChanged={setStartWeek} />
      <Loading loading={loading}>
        {menu?.map((day, index) => {
          return (
            <div
              key={index}
              onDrop={(e) => {
                if (onDrop) {
                  e.preventDefault();
                  e.stopPropagation();
                  onDrop?.(e, day.date);
                }
              }}
              data-drag-active={dragActive}
              className={styles.dishesCard}
            >
              <div className={styles.dishesCardHeader}>
                <div className={styles.dishesCardHeaderWeekday}>
                  {localFormat(day.date, 'EEEE')}
                </div>
                <div className={styles.dishesCardHeaderDate}>
                  {localFormat(day.date, 'PPP')}
                </div>
              </div>
              {day.dishes?.some((x) => x) ? (
                day.dishes?.map((dish, index) => (
                  <DailyDish
                    key={dish.id}
                    id={dish.id}
                    name={dish.name}
                    photoId={dish.photoId}
                    onRemove={(dishId) => onDishRemoved?.(day.date, dishId)}
                    onOrderUp={
                      index === 0
                        ? undefined
                        : () =>
                            onOrderChanged?.(day.date, dish.id, dish.sortId - 1)
                    }
                    enableOrdering={true}
                  />
                ))
              ) : (
                <div className={styles.dailyMenuEmpty}>
                  {t('DailyMenu.Empty')}
                </div>
              )}
            </div>
          );
        })}
      </Loading>
    </div>
  );
};
