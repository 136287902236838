import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { Cell, Row, TableInstance } from 'react-table';

const styles = require('./AppTable.module.scss');

export function AppTable<D extends object = object>(props: {
  table: TableInstance<D>;
  customRowProps?: (row: Row<D>) => object;
  customCellProps?: (row: Cell<D>) => object;
}) {
  const tableObj = props.table;
  return (
    <TableContainer className={styles.tableContainer} component={'div'}>
      <Table
        stickyHeader
        aria-label="sticky table"
        className={styles.table}
        {...tableObj.getTableProps()}
      >
        <TableHead>
          {tableObj.headerGroups.map((headerGroup, index) => (
            <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column) => {
                return (
                  <TableCell
                    key={column.id}
                    width={column.width}
                    className={styles.tableHeaderCellRoot}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...tableObj.getTableBodyProps()}>
          {tableObj.rows.map((row, indexRow) => {
            tableObj.prepareRow(row);
            return (
              <TableRow
                {...row.getRowProps(props.customRowProps?.(row))}
                className={styles.tableBodyRowRoot}
                key={indexRow}
              >
                {row.cells.map((cell, indexCell) => {
                  return (
                    <TableCell
                      {...cell.getCellProps(props.customCellProps?.(cell))}
                      width={cell.column.width}
                      key={indexCell}
                    >
                      {cell.render('Cell')}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
